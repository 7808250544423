/* eslint-disable */
import NewOrder from "@Assets/images/new-order.png";
import Loading from "@Components/Loading";
import ModalFooter from "@Components/ModalFooter";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES } from "@Constants";
import useProfile from "@Hooks/useProfile";
import { ItemPermission, SalesOfficeAccount } from "@Models";
import {
  Backdrop,
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { CustomerService } from "@Services";
import { PlanService } from "@Services/plan.service";
import {
  dateFormat,
  formattedDate,
  FormUIUtil,
  FormUtil,
  getAllDaysOfWeek,
  getAllWeeksOfYear,
  getWeek,
  PermissionUtil,
} from "@Utils";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import TabCustom from "../TabCustom";
import "./index.scss";

const CreatePlan: React.FC = () => {
  const history = useHistory();
  const week = getWeek(new Date());
  const allWeeks = getAllWeeksOfYear(2024);
  const [selectWeek, setSelectWeek] = useState<number | null>(null);
  const [details, setDetails] = useState<any>([]);
  const weekList = (selectWeek && getAllDaysOfWeek(new Date().getFullYear(), selectWeek)) || [];
  const [ordDraft, setOrdDraft] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const { customerBySource } = useProfile("feed");
  const checkWeekUpdate = (selectWeek && selectWeek >= week) || false;
  const [profile, setProfile] = useState<any>([]);

  const loadProfileData = _.debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
    //eslint-disable-next-line
  }, []);

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );
  // const dataSalesOrg =
  //   customerBySource?.customerDivision
  //     ?.map((cusDivision) => {
  //       return cusDivision?.salesOrg;
  //     })
  //     ?.reduce((rs: any, item: any) => {
  //       if (!rs.some((s: any) => s.id === item.id)) {
  //         rs.push(item);
  //       }
  //       return rs;
  //     }, []) || [];

  const dataSalesOffice =
    customerBySource?.customerDivision.flatMap((division) => division.salesOffices) || [];

  const submitForm = async (values: any) => {
    setLoading(true);
    const dataDetails = details
      ?.map((item: any) => {
        return item.details?.map((d: any, index: number) => {
          return { ...d, label: index + 1 };
        });
      })
      ?.flat();

    const dataDetailList = dataDetails
      ?.filter((f: any) => f.items?.filter((item: any) => item.itemId !== 0)?.length > 0)
      ?.map((d: any) => {
        return {
          date: formattedDate(d.date),
          label: "Đơn " + d?.label,
          items: d.items.map((i: any) => {
            return {
              ...i,
              subTotal: checkPermissionViewPrice ? i.subTotal : -1,
              unitPrice: checkPermissionViewPrice ? i.unitPrice : -1,
            };
          }),
        };
      });

    if (dataDetailList?.length === 0) {
      setLoading(false);
      return enqueueSnackbar("Vui lòng kiểm tra lại kế hoạch", {
        variant: "warning",
      });
    }

    const val = {
      name: values.name,
      customerId: customerBySource?.id,
      salesOfficeId: values.salesOfficeId,
      distributionChannelId: customerBySource?.customerDivision?.[0]?.distributionChannel?.id,
      campId: values.campId,
      description: values.description,
      start: dateFormat(
        allWeeks?.find((f) => f.week === selectWeek)?.startDate,
        false,
        "YYYY-MM-DD"
      ),
      end: dateFormat(allWeeks?.find((f) => f.week === selectWeek)?.endDate, false, "YYYY-MM-DD"),
      isDraft: ordDraft,
      details: dataDetailList,
    };

    PlanService.createPlan(val as any)
      .then(async (res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setLoading(false);
          enqueueSnackbar(ordDraft ? "Lưu kế hoạch thành công!" : "Tạo kế hoạch thành công!", {
            variant: "success",
          });
          history.push(APP_ROUTES.PLAN);
        }
      })
      .catch(() => {
        setLoading(false);
        enqueueSnackbar(ordDraft ? "Lưu kế hoạch thất bại" : "Tạo kế hoạch thất bại", {
          variant: "error",
        });
      });
  };

  return (
    <Dialog fullScreen open={true}>
      <div className="plan-create">
        <SimpleModalHeader
          title={"Tạo kế hoạch đặt hàng - Tuần"}
          onClose={() => {
            history.push(APP_ROUTES.PLAN);
          }}
        />
        <div className="content">
          <Form
            onSubmit={async (values: any) => {
              submitForm(values);
            }}>
            {({ handleSubmit, values }) => {
              setSelectWeek(values.week);
              return (
                <form id="plan" onSubmit={handleSubmit} className="plan-form">
                  <Grid container spacing={3} alignItems={"center"} className="plan-form__grid">
                    <Grid item xs={4}>
                      {FormUIUtil.renderFormItem(
                        "Trại",
                        <Field
                          name="campId"
                          validate={FormUtil.Rule.required("Bạn chưa chọn Trại")}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <FormControl style={{ width: "100%" }}>
                                <Select
                                  {...input}
                                  {...rest}
                                  fullWidth
                                  input={
                                    <OutlinedInput className="form-text-field" color="success" />
                                  }
                                  variant="outlined"
                                  renderValue={
                                    input.value !== ""
                                      ? undefined
                                      : () => <span className="form__placeholder">Chọn trại</span>
                                  }
                                  error={meta.error && meta.touched}
                                  displayEmpty
                                  className="form-select-field"
                                  MenuProps={{
                                    className: "select-menu-list",
                                    style: { maxHeight: "40rem" },
                                  }}>
                                  {customerBySource?.camps.map((camp) => {
                                    return (
                                      <MenuItem key={camp.id} value={camp.id}>
                                        {camp.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                              </FormControl>
                            );
                          }}
                        </Field>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={4}>
                      {FormUIUtil.renderFormItem(
                        "Tên kế hoạch",
                        <Field name="name" validate={FormUtil.Rule.required("Nhập tên kế hoạch")}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                placeholder={"Nhập tên kế hoạch"}
                                color="success"
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={meta.error && meta.touched}
                                autoComplete="off"
                              />
                            );
                          }}
                        </Field>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      {FormUIUtil.renderFormItem(
                        "Địa chỉ giao hàng",
                        <Field
                          name="salesOfficeId"
                          validate={FormUtil.Rule.required("Bạn chưa chọn địa chỉ giao hàng")}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <FormControl style={{ width: "100%" }}>
                                <Select
                                  {...input}
                                  {...rest}
                                  fullWidth
                                  input={
                                    <OutlinedInput className="form-text-field" color="success" />
                                  }
                                  variant="outlined"
                                  renderValue={
                                    input.value !== ""
                                      ? undefined
                                      : () => (
                                          <span className="form__placeholder">
                                            Chọn địa chỉ giao hàng
                                          </span>
                                        )
                                  }
                                  error={meta.error && meta.touched}
                                  displayEmpty
                                  className="form-select-field"
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}>
                                  {dataSalesOffice.map((s: SalesOfficeAccount) => {
                                    return (
                                      <MenuItem key={s.id} value={s.id}>
                                        {s.description}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                              </FormControl>
                            );
                          }}
                        </Field>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      {FormUIUtil.renderFormItem(
                        "Tuần",
                        <Field name="week" validate={FormUtil.Rule.required("Bạn chưa chọn Tuần")}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <FormControl style={{ width: "100%" }}>
                                <Select
                                  {...input}
                                  {...rest}
                                  fullWidth
                                  input={
                                    <OutlinedInput className="form-text-field" color="success" />
                                  }
                                  variant="outlined"
                                  renderValue={
                                    input.value !== ""
                                      ? undefined
                                      : () => <span className="form__placeholder">Chọn tuần</span>
                                  }
                                  error={meta.error && meta.touched}
                                  displayEmpty
                                  className="form-select-field"
                                  MenuProps={{
                                    className: "select-menu-list",
                                    style: { maxHeight: "40rem" },
                                  }}>
                                  {allWeeks
                                    .filter((f) => f.week >= week)
                                    .map((weekItem, index: number) => {
                                      return (
                                        <MenuItem key={index} value={weekItem.week}>
                                          {weekItem.week}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                                <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                              </FormControl>
                            );
                          }}
                        </Field>
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      {FormUIUtil.renderFormItem(
                        "Thời gian bắt đầu",
                        <div className="text-time">
                          {dateFormat(allWeeks?.find((f) => f.week === selectWeek)?.startDate)}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      {FormUIUtil.renderFormItem(
                        "Thời gian kết thúc",
                        <div className="text-time">
                          {dateFormat(allWeeks?.find((f) => f.week === selectWeek)?.endDate)}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={8}>
                      {FormUIUtil.renderFormItem(
                        "Mô tả",
                        <Field name="description">
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                placeholder={"Nhập mô tả"}
                                color="success"
                                className="form-textarea-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={meta.error && meta.touched}
                                rows={1}
                                multiline
                                fullWidth
                              />
                            );
                          }}
                        </Field>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {weekList?.length > 0 && customerBySource ? (
                        <>
                          <div className="plan-create__title">BẢNG KẾ HOẠCH</div>
                          <TabCustom
                            weekList={weekList}
                            customerId={+customerBySource?.id}
                            onChange={(data) => setDetails(data)}
                            checkWeekUpdate={checkWeekUpdate}
                          />
                        </>
                      ) : (
                        <div className="img-empty">
                          <img src={NewOrder} alt="new-order" />
                          <div className="img-empty__text">Bạn nhập thông tin khách hàng</div>
                          <div>để bắt đầu tạo kế hoạch</div>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Form>
        </div>

        <ModalFooter
          formId="plan"
          showDeleteButton={false}
          handleDeleteAction={() => {}}
          title={"Tạo"}
          children={
            <input
              value="Lưu nháp"
              aria-label="submit supplier form"
              form="plan"
              type="submit"
              className="btn-border-confirm-style"
              onClick={() => setOrdDraft(true)}
            />
          }
        />

        {loading && (
          <Backdrop sx={{ color: "red", opacity: 0.5, zIndex: 100 }} open={true}>
            <Loading />
          </Backdrop>
        )}
      </div>
    </Dialog>
  );
};
export default CreatePlan;
