/* eslint-disable */
import Loading from "@Components/Loading";
import ModalFooter from "@Components/ModalFooter";
import Notification from "@Components/Notification";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES } from "@Constants";
import TabCustom from "@Containers/Plan/TabCustom";
import useProfile from "@Hooks/useProfile";
import { ItemPermission, ItemsPlanOutputModel, PlanOutputModel, SalesOfficeAccount } from "@Models";
import { PriorityHighRounded as PriorityHighRoundedIcon } from "@mui/icons-material";
import {
  Backdrop,
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { CustomerService } from "@Services";
import { PlanService } from "@Services/plan.service";
import {
  dateFormat,
  formattedDate,
  FormUIUtil,
  FormUtil,
  getAllDaysOfWeek,
  getAllWeeksOfYear,
  getFirstNumberOfStr,
  getWeek,
  PermissionUtil,
} from "@Utils";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./index.scss";

const DraftPlan: React.FC = () => {
  const history = useHistory();
  const week = getWeek(new Date());
  const allWeeks = getAllWeeksOfYear(new Date().getFullYear());
  const [selectWeek, setSelectWeek] = useState<number | null>(null);
  const [details, setDetails] = useState<any>([]);
  const [ordDraft, setOrdDraft] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const { planId }: { planId: string } = useParams();
  const [detailPlan, setDetailPlan] = useState<PlanOutputModel | null>(null);
  const [notiPlanDel, setNotiPlanDel] = useState<boolean>(false);
  const location = useLocation<any>();
  const mounted = useRef(false);
  const checkRecreate = location?.pathname?.includes("recreate");
  const { customerBySource } = useProfile("feed");

  const [profile, setProfile] = useState<any>([]);

  const loadProfileData = _.debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
    //eslint-disable-next-line
  }, []);

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  const weekList =
    (detailPlan &&
      getAllDaysOfWeek(
        new Date(detailPlan?.start).getFullYear(),
        getWeek(new Date(detailPlan?.start))
      )) ||
    [];

  const checkWeekUpdate = (selectWeek && selectWeek >= week) || false;
  let weekListNow = selectWeek ? getAllDaysOfWeek(new Date().getFullYear(), selectWeek) : [];

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchData: () => Promise<void> = async () => {
      try {
        const result = await PlanService.getPlanById(planId);
        if (mounted.current) {
          if (!checkRecreate) {
            setSelectWeek(getWeek(new Date(result.data?.start)));
          } else {
            setSelectWeek(getWeek(new Date()));
          }
          setDetailPlan(result.data);
        }
      } catch (err: any) {
        console.log(err);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMemo(() => {
    if (selectWeek && weekList?.length > 0) {
      setDetails(
        weekList
          ?.map((w, key: number) => {
            const data = detailPlan?.details;
            const wNow = getAllDaysOfWeek(new Date().getFullYear(), selectWeek);
            return {
              key: key,
              details: data
                ?.filter((f: any) => f.date === dateFormat(w, false, "YYYY-MM-DD"))
                .map((d: any) => {
                  return {
                    date: checkRecreate ? dateFormat(wNow[key]) : dateFormat(d.date),
                    id: getFirstNumberOfStr(d.label),
                    items: d.items?.map((item: ItemsPlanOutputModel) => {
                      return {
                        itemId: item.item.id,
                        quantity: +item.quantity,
                        name: item.item.name,
                        code: item.item.code,
                        divisionId: item.division.id,
                        unitPrice: item.unitPrice,
                        saleUnit: item.saleUnit,
                        dimension: +item.dimension,
                        baseUoM: item.baseUoM,
                        subTotal: +item.subTotal,
                      };
                    }),
                  };
                }),
            };
          })
          ?.filter((f) => f.details && f.details?.length > 0)
      );
    }
  }, [week, detailPlan]);

  // const dataSalesOrg =
  //   customerBySource?.customerDivision
  //     ?.map((cusDivision: any) => {
  //       return cusDivision?.salesOrg;
  //     })
  //     ?.reduce((rs: any, item: any) => {
  //       if (!rs.some((s: any) => s.id === item.id)) {
  //         rs.push(item);
  //       }
  //       return rs;
  //     }, []) || [];

  const dataSalesOffice =
    customerBySource?.customerDivision.flatMap((division) => division.salesOffices) || [];

  const submitForm = async (values: any) => {
    setLoading(true);
    const dataDetails = details
      ?.map((item: any) => {
        return item.details?.map((d: any, index: number) => {
          return {
            ...d,
            label: index + 1,
            date2: dateFormat(weekListNow?.[item.key], false, "YYYY-MM-DD"),
          };
        });
      })
      ?.flat();

    const dataDetailList = dataDetails
      ?.filter((f: any) => f.items?.filter((item: any) => item.itemId !== 0)?.length > 0)
      ?.map((d: any) => {
        return {
          date: formattedDate(d.date2),
          label: "Đơn " + d?.label,
          items: d.items.map((i: any) => {
            return {
              ...i,
              subTotal: checkPermissionViewPrice ? i.subTotal : -1,
              unitPrice: checkPermissionViewPrice ? i.unitPrice : -1,
            };
          }),
        };
      });

    if (dataDetailList?.length === 0) {
      setLoading(false);
      return enqueueSnackbar("Vui lòng kiểm tra lại kế hoạch", {
        variant: "warning",
      });
    }

    const val = {
      name: values.name,
      customerId: detailPlan?.customer.id,
      salesOfficeId: !!values.salesOfficeId ? values.salesOfficeId : detailPlan?.salesOffice?.id,
      distributionChannelId: detailPlan?.distributionChannel.id,
      campId: detailPlan?.camp.id,
      description: values.description,
      start: dateFormat(
        allWeeks?.find((f) => f.week === selectWeek)?.startDate,
        false,
        "YYYY-MM-DD"
      ),
      end: dateFormat(allWeeks?.find((f) => f.week === selectWeek)?.endDate, false, "YYYY-MM-DD"),
      isDraft: ordDraft,
      details: dataDetailList,
    };

    if (checkRecreate) {
      PlanService.createPlan(val as any)
        .then(async (res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            setLoading(false);
            enqueueSnackbar(ordDraft ? "Lưu kế hoạch thành công!" : "Tạo kế hoạch thành công!", {
              variant: "success",
            });
            history.push(APP_ROUTES.PLAN);
          }
        })
        .catch(() => {
          setLoading(false);
          enqueueSnackbar(ordDraft ? "Lưu kế hoạch thất bại" : "Tạo kế hoạch thất bại", {
            variant: "error",
          });
        });
    } else {
      PlanService.updatePlanById(planId, val as any)
        .then(async (res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            setLoading(false);
            enqueueSnackbar("Cập nhật kế hoạch thành công!", {
              variant: "success",
            });
            history.push(APP_ROUTES.PLAN);
          }
        })
        .catch(() => {
          setLoading(false);
          enqueueSnackbar("Cập nhật kế hoạch thất bại", {
            variant: "error",
          });
        });
    }
  };

  const deletePlan = async () => {
    await PlanService.deletePlan(planId)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          enqueueSnackbar("Xóa kế hoạch thành công!", {
            variant: "success",
          });
          history.push(APP_ROUTES.PLAN);
        } else {
          enqueueSnackbar("Xóa kế hoạch thất bại", {
            variant: "error",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Xóa kế hoạch thất bại", {
          variant: "error",
        });
      });
  };

  return (
    <Dialog fullScreen open={true}>
      <div className="draft-plan">
        <SimpleModalHeader
          title={"Tạo kế hoạch đặt hàng - Tuần"}
          onClose={() => {
            checkRecreate
              ? history.push(APP_ROUTES.PLAN_DETAIL.replace(":planId", planId))
              : history.push({
                  pathname: APP_ROUTES.PLAN,
                  state: {
                    startTime: location.state?.startTime,
                    endTime: location.state?.endTime,
                    status: location.state?.status,
                  },
                });
          }}
        />
        {!!detailPlan && customerBySource ? (
          <div className="content">
            <Form
              onSubmit={async (values: any) => {
                submitForm(values);
              }}
              initialValues={{
                ...detailPlan,
                week: checkRecreate ? getWeek(new Date()) : getWeek(new Date(detailPlan?.start)),
                salesOrgId: detailPlan.saleOrg.id,
                salesOfficeId: detailPlan?.salesOffice?.id,
              }}>
              {({ handleSubmit, values }) => {
                setSelectWeek(values.week);
                return (
                  <form id="plan" onSubmit={handleSubmit} className="plan-form">
                    <Grid container spacing={3} className="plan-form__grid">
                      <Grid item xs={4}>
                        {FormUIUtil.renderFormItem(
                          "Trại",
                          <div className="text-time">{detailPlan.camp.name}</div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        {FormUIUtil.renderFormItem(
                          "Tên kế hoạch",
                          <Field name="name" validate={FormUtil.Rule.required("Nhập tên kế hoạch")}>
                            {({ input, meta, ...rest }) => {
                              return (
                                <TextField
                                  {...input}
                                  {...rest}
                                  placeholder={"Nhập tên kế hoạch"}
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                  autoComplete="off"
                                  disabled={!checkWeekUpdate}
                                />
                              );
                            }}
                          </Field>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        {FormUIUtil.renderFormItem(
                          "Địa chỉ giao hàng",
                          <Field
                            name="salesOfficeId"
                            validate={FormUtil.Rule.required("Bạn chưa chọn địa chỉ giao hàng")}>
                            {({ input, meta, ...rest }) => {
                              return (
                                <FormControl style={{ width: "100%" }}>
                                  <Select
                                    {...input}
                                    {...rest}
                                    fullWidth
                                    input={
                                      <OutlinedInput className="form-text-field" color="success" />
                                    }
                                    variant="outlined"
                                    renderValue={
                                      input.value !== ""
                                        ? undefined
                                        : () => (
                                            <span className="form__placeholder">
                                              Chọn địa chỉ giao hàng
                                            </span>
                                          )
                                    }
                                    error={meta.error && meta.touched}
                                    displayEmpty
                                    className="form-select-field"
                                    MenuProps={{
                                      className: "select-menu-list",
                                    }}
                                    disabled={!checkWeekUpdate}>
                                    {dataSalesOffice.map((s: SalesOfficeAccount) => {
                                      return (
                                        <MenuItem key={s.id} value={s.id}>
                                          {s.description}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                                </FormControl>
                              );
                            }}
                          </Field>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        {FormUIUtil.renderFormItem(
                          "Tuần",
                          <Field
                            name="week"
                            validate={FormUtil.Rule.required("Bạn chưa chọn Tuần")}>
                            {({ input, meta, ...rest }) => {
                              return (
                                <FormControl style={{ width: "100%" }}>
                                  <Select
                                    {...input}
                                    {...rest}
                                    fullWidth
                                    input={
                                      <OutlinedInput className="form-text-field" color="success" />
                                    }
                                    variant="outlined"
                                    renderValue={
                                      input.value !== ""
                                        ? undefined
                                        : () => <span className="form__placeholder">Chọn tuần</span>
                                    }
                                    error={meta.error && meta.touched}
                                    displayEmpty
                                    className="form-select-field"
                                    MenuProps={{
                                      className: "select-menu-list",
                                      style: { maxHeight: "40rem" },
                                    }}
                                    disabled={!checkWeekUpdate}>
                                    {allWeeks
                                      .filter((f) =>
                                        checkWeekUpdate
                                          ? f.week >= week
                                          : selectWeek && f.week >= selectWeek
                                      )
                                      .map((weekItem, index: number) => {
                                        return (
                                          <MenuItem key={index} value={weekItem.week}>
                                            {weekItem.week}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                  <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                                </FormControl>
                              );
                            }}
                          </Field>
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        {FormUIUtil.renderFormItem(
                          "Thời gian bắt đầu",
                          <div className="text-time">
                            {dateFormat(allWeeks?.find((f) => f.week === selectWeek)?.startDate)}
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        {FormUIUtil.renderFormItem(
                          "Thời gian kết thúc",
                          <div className="text-time">
                            {dateFormat(allWeeks?.find((f) => f.week === selectWeek)?.endDate)}
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={8}>
                        {FormUIUtil.renderFormItem(
                          "Mô tả",
                          <Field name="description">
                            {({ input, meta, ...rest }) => {
                              return (
                                <TextField
                                  {...input}
                                  {...rest}
                                  placeholder={"Nhập mô tả"}
                                  color="success"
                                  className="form-textarea-field"
                                  inputProps={{ className: "input" }}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                  rows={1}
                                  multiline
                                  fullWidth
                                  disabled={!checkWeekUpdate}
                                />
                              );
                            }}
                          </Field>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <div className="draft-plan__title">BẢNG KẾ HOẠCH</div>
                        <TabCustom
                          // weekList={checkRecreate ? weekListNow : weekList}
                          weekList={weekListNow}
                          customerId={detailPlan?.customer.id}
                          onChange={(data) => setDetails(data)}
                          dataDetails={details}
                          checkWeekUpdate={checkWeekUpdate}
                        />
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Form>
          </div>
        ) : (
          <Loading style={{ margin: "2rem 0", height: "80vh" }} />
        )}

        {checkWeekUpdate ? (
          <ModalFooter
            formId="plan"
            showDeleteButton={checkRecreate ? false : true}
            handleDeleteAction={() => setNotiPlanDel(true)}
            title={"Tạo"}
            titleDel={"Hủy"}
            children={
              <input
                value="Lưu nháp"
                aria-label="submit supplier form"
                form="plan"
                type="submit"
                className="btn-border-confirm-style"
                onClick={() => setOrdDraft(true)}
              />
            }
          />
        ) : (
          <div className="modal-footer">
            <div className="col-text">Bạn không được chỉnh sửa kế hoạch đặt hàng trong quá khứ</div>
          </div>
        )}

        {loading && (
          <Backdrop sx={{ color: "red", opacity: 0.5, zIndex: 100 }} open={true}>
            <Loading />
          </Backdrop>
        )}

        <Notification
          visible={notiPlanDel}
          title="Hủy kế hoạch đặt hàng"
          subTitle={<span>Bạn có chắc muốn hủy kế hoạch đặt hàng không?</span>}
          icon={
            <div className={`notification-icon-box yellow`}>
              <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            </div>
          }
          actions={
            <>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  setNotiPlanDel(false);
                }}>
                Không
              </button>
              <button
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginLeft: "2rem",
                }}
                onClick={() => {
                  deletePlan();
                }}>
                Xác nhận
              </button>
            </>
          }
          onClose={() => {
            setNotiPlanDel(false);
          }}
        />
      </div>
    </Dialog>
  );
};
export default DraftPlan;
