import { SourceType } from "./other.model";

export type CustomerOutputModel = {
  id: string;
  email: string;
  phoneNumber: string;
  isActive: boolean;
  fullName: string;
  idCard: string | null;
  source: string;
  code: string;
  idIssueDate: string | null;
  idIssuePlace: string | null;
  businessRegistration: string | null;
  company: string | null;
  contactName: string;
  address: string;
  payTerm: string | null;
  account: {
    id: string;
    avatar: string | null;
    idCardFront: string | null;
    idCardBack: string | null;
    signature: string | null;
  };
  createdAt: string;
  updatedAt: string;
};

export type AuthCustomerProfileOutputModel = {
  id: string;
  email: string;
  avatar: string | null;
  phoneNumber: string;
  isActive: boolean;
  code: string | null;
  deviceIds: [];
  permissions: string | null;
  idCardFront: string | null;
  idCardBack: string | null;
  signature: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  customer: AuthCustomer[];
  customers: AuthCustomer[];
  role: Role;
};
export type AuthCustomer = {
  address: string;
  businessRegistration: string | null;
  code: string;
  company: string | null;
  contactName: string | null;
  createdAt: string;
  email: string;
  fullName: string;
  id: number;
  idCard: string | null;
  idIssueDate: string;
  idIssuePlace: string | null;
  isActive: true;
  payTerm: string;
  phoneNumber: string;
  source: string;
  updatedAt: string;
  camps: CampsModel[];
  customerDivision: CustomerDivision[];
  customerDivisions: CustomerDivision[];
};

export type CustomerGroupOutputModel = {
  id: number;
  code: number;
  description: string;
  createdAt: string;
  updatedAt: string;
  source: SourceType;
  name: string;
};

export type Camp = {
  id: number;
  code: string;
  name?: string;
  source: string;
  createdAt: Date;
  updatedAt: Date;
  description?: string;
};

export type CustomerDivision = {
  id: number;
  code: null;
  description: null;
  deliveryPlant: null;
  createdAt: Date;
  updatedAt: Date;
  division: DistributionChannel;
  salesOrg: Camp;
  distributionChannel: DistributionChannel;
  salesOffices: SalesOfficeAccount[];
};

export type DistributionChannel = {
  id: number;
  code: number;
  description: string;
  source: string;
  createdAt: Date;
  updatedAt: Date;
  divisionImage?: null;
};

export type Role = {
  id: number;
  position: string;
  permissions: string[];
  description: string;
  fixed: boolean;
  roleType: string;
  createdAt: Date;
  updatedAt: Date;
  dataAccessPermissions: DataAccessPermission[];
};

export type DataAccessPermission = {
  id: number;
  source: string;
  isActive: boolean;
  divisionIds: number[];
  salesOrgIds: number[];
  siteIds: null;
  itemIds: null;
  areaIds: null;
  buIds: null;
  salesManIds: number[];
  createdAt: Date;
  updatedAt: Date;
};

export type AuthProfileCustomerCampOutputModel = {
  address: null;
  businessRegistration: null;
  code: string;
  company: null;
  contactName: null;
  createdAt: string;
  id: number;
  idCard: null;
  idIssueDate: null;
  idIssuePlace: null;
  name: string;
  payTerm: null;
  phoneNumber: null;
  source: string;
  updatedAt: string;
};

export type AuthProfileCustomerOutputModel = {
  address: string;
  businessRegistration: string;
  camps: AuthProfileCustomerCampOutputModel[];
  code: string;
  company: string;
  contactName: string;
  createdAt: string;
  customerDivision: CustomerDivision[];
  debt: {
    postingDate: string;
    source: string;
    totalDebt: string;
    totalOverdueDebt: string;
    updatedAt: string;
  };
  email: null;
  fullName: string;
  id: number;
  idCard: string;
  idIssueDate: string;
  idIssuePlace: string;
  isActive: boolean;
  payTerm: string;
  phoneNumber: string;
  source: string;
  updatedAt: string;
};

export type AuthProfileOutputModel = {
  avatar: null;
  cancelled: boolean;
  code: string;
  createdAt: string;
  customer: AuthProfileCustomerOutputModel[];
  deviceIds: string[];
  email: null;
  id: number;
  idCardBack: null;
  idCardFront: null;
  isActive: boolean;
  isEmployee: boolean;
  nickname: string;
  notification: number;
  phoneNumber: string;
  requireOTP: boolean;
  role: Role;
  signature: null;
  updatedAt: string;
  policyAccepted: boolean;
  policyAcceptedAt: null;
};

export type AccountProfileModel = {
  id: number;
  email: string;
  avatar: null;
  phoneNumber: string;
  nickname: string;
  isActive: boolean;
  cancelled: boolean;
  requireOTP: boolean;
  code: string;
  deviceIds: string[];
  isEmployee: boolean;
  idCardFront: null;
  idCardBack: null;
  signature: null;
  createdAt: string;
  updatedAt: string;
  isSubAccount: boolean;
  customer: AuthCustomer[];
  role: Role;
  notification: number;
};

export type CampsModel = {
  id: number;
  code: string;
  name?: string;
  source: string;
  createdAt: string;
  updatedAt: string;
  description?: string;
  phoneNumber: null;
  idCard: null;
  idIssueDate: null;
  idIssuePlace: null;
  businessRegistration: null;
  company: null;
  contactName: null;
  address: null;
  payTerm: null;
};

export type CustomerAccount = {
  id: number;
  email: null | string;
  code: string;
  phoneNumber: string;
  isActive: boolean;
  fullName: string;
  idCard: string;
  source: string;
  idIssueDate: string;
  idIssuePlace: string;
  businessRegistration: string;
  company: null | string;
  contactName: string;
  address: string;
  payTerm: string;
  createdAt: string;
  updatedAt: string;
  camps: CampsAccount[];
  customerDivision: CustomerDivisionAccount[];
};

export type RoleAccount = {
  id: number;
  position: string;
  permissions: string[];
  description: string;
  fixed: boolean;
  roleType: string;
  createdAt: string;
  updatedAt: string;
};

export type CampsAccount = {
  id: number;
  code: string;
  name: string;
  phoneNumber: null;
  idCard: null;
  idIssueDate: null;
  idIssuePlace: null;
  businessRegistration: null;
  company: null;
  contactName: null;
  address: null;
  payTerm: null;
  source: string;
  createdAt: string;
  updatedAt: string;
};

export type CustomerDivisionAccount = {
  id: number;
  code: null;
  description: null;
  deliveryPlant: null;
  createdAt: Date;
  updatedAt: Date;
  salesOffices: SalesOfficeAccount[];
};

export type SalesOfficeAccount = {
  id: number;
  code: number;
  source: string;
  description: string;
  slugSearch: string;
  createdAt: Date;
  updatedAt: Date;
};

export type SubAccountDetailModel = {
  id: number;
  nickname: string;
  phoneNumber: string;
  email: string;
  requireOTP: boolean;
  code: string;
  createdAt: string;
  camps: CampsAccount[];
  role: RoleAccount;
  saleOffices: SalesOfficeAccount[];
  status: string;
  accessLogs: any;
  avatar: any;
  cancelled: any;
  deviceIds: any;
  employee: any[];
  idCardBack: any;
  idCardFront: any;
  isActive: boolean;
  isEmployee: boolean;
  notification: number;
  customers: CustomerAccount[];
  parentAccount: {
    id: number;
    nickname: string;
    code: string;
  };
  permissionNote: string;
};

export type SubAccountModel = {
  id: number;
  nickname: string;
  phoneNumber: string;
  email: string;
  requireOTP: boolean;
  code: string;
  createdAt: Date;
  camps: null;
  role: RoleAccount;
  saleOffices: null;
  status: string;
  accessLogs: null;
  avatar: null;
  cancelled: null;
  deviceIds: null;
  employee: any[];
  idCardBack: null;
  idCardFront: null;
  isActive: boolean;
  isEmployee: boolean;
  notification: number;
  customers: any[];
  parentAccount: ParentAccount;
};

export type ParentAccount = {
  id: number;
  nickname: string;
  code: string;
};

//policy

export type PolicyOutputModel = {
  id: number;
  title: string;
  body: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  policyAccepted: boolean;
};

//input
export type SubAccountInputModel = {
  customerAccountId: number;
  isDraft: boolean;
  requestEmail: string;
  requestPhoneNumber: string;
  requestFullName: string;
  requestSaleOfficeIds: number[];
  requestRoleId: number;
  requestCamps: {
    source: string;
    campId: number | null;
    isActive?: boolean;
  }[];
  code?: string;
  status?: string;
  permissionNote: string;
};

export enum LogType {
  Login = "login",
  SignUp = "sign-up",
}
