import ChipCustom from "@Components/ChipCustom";
import { OrderStatusLab } from "@Constants";
import { OrderStatus, PlanDetailStatus, PlanStatus, SourceType } from "@Models";
import { ArrowRight as ArrowRightIcon, Circle as CircleIcon } from "@mui/icons-material";
import i18n from "src/i18n";

export const StatusUIUtil = {
  renderCircleStatus,
  renderBgStatus,
  renderStatusPlan,
  renderBgStatusPlan,
  renderBgStatusPlanDetail,
  renderCircleStatusPlanDetail,
};

function renderCircleStatus(selectTab: any, selected: any, source?: any, subStatus?: any) {
  return (
    <div className="circle-status">
      {(() => {
        switch (selectTab === "all" ? selected : selectTab) {
          case OrderStatus.DRAFT:
            return (
              <span style={{ color: "#004125" }}>
                <CircleIcon />
                &ensp; {source === SourceType.LAB ? i18n.t("LAB_DRAFT") : i18n.t("CART")}
              </span>
            );
          case OrderStatus.CREATED:
            return (
              <span style={{ color: "#41AD49" }}>
                <CircleIcon />
                &ensp; {source === SourceType.LAB ? i18n.t("LAB_CREATED") : i18n.t("CREATED")}
              </span>
            );
          case OrderStatus.CONFIRMED:
            return (
              <span style={{ color: "#41AD49" }}>
                <CircleIcon />
                &ensp; {source === SourceType.LAB ? i18n.t("LAB_CONFIRMED") : i18n.t("RECORDED")}
              </span>
            );
          case OrderStatus.PROCESSING:
            return (
              <span style={{ color: "#0A6836" }}>
                <CircleIcon />
                &ensp; {source === SourceType.LAB ? i18n.t("LAB_PROCESSING") : i18n.t("DELIVERING")}
              </span>
            );
          case OrderStatusLab.SCHEDULED:
            return (
              source === SourceType.LAB && (
                <span style={{ color: "#0A6836" }}>
                  <CircleIcon />
                  &ensp; {i18n.t("LAB_SCHEDULED")}
                </span>
              )
            );
          case OrderStatusLab.RECEIVED:
            return (
              source === SourceType.LAB && (
                <span style={{ color: "#0A6836" }}>
                  <CircleIcon />
                  &ensp; {i18n.t("LAB_RECEIVED")}
                </span>
              )
            );
          case OrderStatus.HALF_COMPLETED:
            return (
              <span style={{ color: "#0A6836" }}>
                <CircleIcon />
                &ensp; {i18n.t("PARTIALLY_DELIVERED")}
              </span>
            );
          case OrderStatus.COMPLETED:
            return (
              <span style={{ color: "#0A6836" }}>
                <CircleIcon />
                &ensp; {source === SourceType.LAB ? i18n.t("LAB_COMPLETED") : i18n.t("DELIVERED")}
              </span>
            );
          case OrderStatus.CANCELLED:
            return (
              <span style={{ color: "#B8292F" }}>
                <CircleIcon />
                &ensp; {i18n.t("CANCELLED")}
              </span>
            );
          case OrderStatusLab.REJECTED:
            return (
              <span style={{ color: "#B8292F" }}>
                <CircleIcon />
                &ensp; {i18n.t("REJECTED")}
              </span>
            );
          case OrderStatus.VERIFIED:
            return (
              <span style={{ color: "#41AD49" }}>
                <CircleIcon />
                &ensp; {i18n.t("PENDING")}
              </span>
            );
          default:
            return;
        }
      })()}
    </div>
  );
}

function renderBgStatus(value: any, source?: string) {
  switch (value) {
    case OrderStatus.DRAFT:
      return (
        <span style={{ background: "#004125" }}>
          {source === SourceType.LAB ? i18n.t("LAB_DRAFT") : i18n.t("CART")}
        </span>
      );
    case OrderStatus.CREATED:
      return (
        <span style={{ background: "#41AD49" }}>
          {source === SourceType.LAB ? i18n.t("LAB_CREATED") : i18n.t("CREATED")}
        </span>
      );
    case OrderStatus.CONFIRMED:
      return (
        <span style={{ background: "#41AD49" }}>
          {source === SourceType.FARM
            ? i18n.t("DISPATCHED")
            : source === SourceType.LAB
            ? i18n.t("LAB_CONFIRMED")
            : i18n.t("RECORDED")}
        </span>
      );
    case OrderStatus.PROCESSING:
      return (
        <span style={{ background: "#41AD49" }}>
          {source === SourceType.LAB ? i18n.t("LAB_PROCESSING") : i18n.t("DELIVERING")}
        </span>
      );
    case OrderStatusLab.SCHEDULED:
      return <span style={{ background: "#0A6836" }}>{i18n.t("SCHEDULED")}</span>;
    case OrderStatusLab.RECEIVED:
      return <span style={{ background: "#0A6836" }}>{i18n.t("LAB_RECEIVED")}</span>;
    case OrderStatus.HALF_COMPLETED:
      return <span style={{ background: "#0A6836" }}>{i18n.t("PARTIALLY_DELIVERED")}</span>;
    case OrderStatus.COMPLETED:
      return (
        <span style={{ background: "#0A6836 " }}>
          {source === SourceType.LAB ? i18n.t("LAB_COMPLETED") : i18n.t("DELIVERED")}
        </span>
      );
    case OrderStatus.CANCELLED:
      return <span style={{ background: "#B8292F" }}>{i18n.t("CANCELLED")}</span>;
    case OrderStatusLab.REJECTED:
      return <span style={{ background: "#B8292F" }}>{i18n.t("REJECTED")}</span>;
    case OrderStatus.VERIFIED:
      return (
        <span style={{ background: "#41AD49" }}>
          {source === "farm" ? i18n.t("PROCESSING") : i18n.t("PENDING")}
        </span>
      );
    default:
      return;
  }
}

function renderStatusPlan(status: string) {
  return (
    <div className="circle-status">
      {(() => {
        switch (status) {
          case PlanStatus.OPENING:
            return (
              <span style={{ color: "#004125" }}>
                <CircleIcon />
                &ensp; Đang soạn
              </span>
            );
          case PlanStatus.CREATED:
            return (
              <span style={{ color: "#41AD49" }}>
                <CircleIcon />
                &ensp; Đã tạo
              </span>
            );
          case PlanStatus.ADAPTED:
            return (
              <span style={{ color: "#0A6836" }}>
                <CircleIcon />
                &ensp; Đáp ứng
              </span>
            );
          case PlanStatus.CHANGING:
            return (
              <span style={{ color: "#41AD49" }}>
                <CircleIcon />
                &ensp; Cần điều chỉnh
              </span>
            );
          case PlanStatus.CONFIRMED:
            return (
              <span style={{ color: "#0A6836" }}>
                <CircleIcon />
                &ensp; Đã xác nhận
              </span>
            );
          case PlanStatus.PROCESSING:
            return (
              <span style={{ color: "#0A6836" }}>
                <CircleIcon />
                &ensp; Đang xử lý
              </span>
            );
          case PlanStatus.REJECTED:
            return (
              <span style={{ color: "#B8292F" }}>
                <CircleIcon />
                &ensp; Từ chối
              </span>
            );
          case PlanStatus.CLOSED:
            return (
              <span style={{ color: "#B8292F" }}>
                <CircleIcon />
                &ensp; Đã đóng
              </span>
            );
          case PlanStatus.NOT_ADAPTED:
            return (
              <span style={{ color: "#B8292F" }}>
                <CircleIcon />
                &ensp; Không đáp ứng
              </span>
            );
          default:
            return;
        }
      })()}
    </div>
  );
}

function renderBgStatusPlan(value: any) {
  switch (value) {
    case PlanStatus.OPENING:
      return <ChipCustom label="Đang soạn" background="#004125" uppercase />;
    case PlanStatus.CREATED:
      return <ChipCustom label="Đã tạo" background="#41AD49" uppercase />;
    case PlanStatus.ADAPTED:
      return <ChipCustom label="Đáp ứng" background="#0A6836" uppercase />;
    case PlanStatus.CHANGING:
      return <ChipCustom label="Cần điều chỉnh" background="#41AD49" uppercase />;
    case PlanStatus.CONFIRMED:
      return <ChipCustom label="Đã xác nhận" background="#0A6836" uppercase />;
    case PlanStatus.PROCESSING:
      return <ChipCustom label="Đang xử lý" background="#0A6836" uppercase />;
    case PlanStatus.REJECTED:
      return <ChipCustom label="Từ chối" background="#B8292F" uppercase />;
    case PlanStatus.CLOSED:
      return <ChipCustom label="Đã đóng" background="#B8292F" uppercase />;
    case PlanStatus.NOT_ADAPTED:
      return <ChipCustom label="Không đáp ứng" background="#B8292F" uppercase />;
    default:
      return;
  }
}

function renderBgStatusPlanDetail(value: any, label?: boolean) {
  switch (value) {
    case PlanDetailStatus.CHANGING:
      return (
        <ChipCustom
          label={label ? "Yêu cầu chỉnh sửa" : ""}
          avatar={label ? undefined : <ArrowRightIcon style={{ color: "#fff" }} />}
          background="#41AD49"
          width={label ? "22rem" : undefined}
        />
      );
    case PlanDetailStatus.APPROVED:
      return (
        <ChipCustom
          label={label ? "Đã xác nhận" : ""}
          avatar={label ? undefined : <ArrowRightIcon style={{ color: "#fff" }} />}
          background="#0A6836"
          width={label ? "22rem" : undefined}
        />
      );
    case PlanDetailStatus.REJECTED:
      return (
        <ChipCustom
          label={label ? "Đã từ chối" : ""}
          avatar={label ? undefined : <ArrowRightIcon style={{ color: "#fff" }} />}
          background="#B8292F"
          width={label ? "22rem" : undefined}
        />
      );
    case PlanDetailStatus.PENDING:
      return (
        <ChipCustom
          label={label ? "Chờ duyệt" : ""}
          avatar={label ? undefined : <ArrowRightIcon style={{ color: "#fff" }} />}
          background="#0A6836"
          width={label ? "22rem" : undefined}
        />
      );
    default:
      return;
  }
}

function renderCircleStatusPlanDetail(value: any) {
  switch (value) {
    case PlanDetailStatus.CHANGING:
      return (
        <span>
          <CircleIcon style={{ color: "#41AD49" }} />
          &ensp; Yêu cầu chỉnh sửa
        </span>
      );
    case PlanDetailStatus.APPROVED:
      return (
        <span>
          <CircleIcon style={{ color: "#0A6836" }} />
          &ensp; Xác nhận
        </span>
      );
    case PlanDetailStatus.REJECTED:
      return (
        <span>
          <CircleIcon style={{ color: "#B8292F" }} />
          &ensp; Từ chối
        </span>
      );
    default:
      return;
  }
}
