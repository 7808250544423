import Loading from "@Components/Loading";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, REGEX } from "@Constants";
import {
  CustomerAccount,
  RoleAccount,
  SourceType,
  SubAccountDetailModel,
  SubAccountInputModel,
} from "@Models";
import { CustomerService } from "@Services";
import { FormUtil } from "@Utils";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";
import { Checkbox, Dialog, FormControl, MenuItem, Select, TextField } from "@mui/material";
import _debounce from "lodash/debounce";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import "./SubAccountModal.scss";

const UpdateSubAccountModal: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { accountId }: { accountId: string } = useParams();
  const submitRef = useRef<any | null>(null);
  const mounted = useRef(false);
  const [requireOTP, setRequireOTP] = useState<boolean>(false);
  const [selectedCustomerFeed, setSelectedCustomerFeed] = useState<number | null>(null);
  const [selectedCustomerFarm, setSelectedCustomerFarm] = useState<number | null>(null);
  const [selectedCustomerVet, setSelectedCustomerVet] = useState<number | null>(null);
  const [selectedCustomerLab, setSelectedCustomerLab] = useState<number | null>(null);
  const [selectedCustomerCcu, setSelectedCustomerCcu] = useState<number | null>(null);
  const [dataRole, setDataRole] = useState<RoleAccount | null>(null);
  const [valid, setValid] = useState<boolean>(false);
  const [ordDraft, setOrdDraft] = useState<boolean>(false);
  const [deliveryAddress, setDeliveryAddress] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataDetail, setDataDetail] = useState<SubAccountDetailModel | null>(null);
  const [dataCustomer, setDataCustomer] = useState<CustomerAccount[]>([]);

  const [requestCamps, setRequestCamp] = useState<
    { campId: number | null; source: string; active?: boolean }[]
  >([]);

  const [isActiveSource, setIsActiveSource] = useState<{
    feed: boolean;
    farm: boolean;
    vet: boolean;
    lab: boolean;
    ccu: boolean;
  }>({
    feed: false,
    farm: false,
    vet: false,
    lab: false,
    ccu: false,
  });

  const loadProfileData = _debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setDataCustomer(() => response.data?.customer);
    setDataRole(() => response.data?.role);
  }, 100);

  useEffect(() => {
    loadProfileData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!!accountId) {
      onFetchApiSubAccountDetail(accountId);
    }
    //eslint-disable-next-line
  }, [accountId]);

  const onFetchApiSubAccountDetail: (id: string) => Promise<void> = async (id: string) => {
    setLoading(true);
    try {
      const response = await CustomerService.getSubAccountById(id);
      if (mounted.current) {
        const resData: SubAccountDetailModel = response.data;
        setDataDetail(() => resData);
        setDeliveryAddress(resData.saleOffices.map((i) => i.id));
        setRequireOTP(resData.requireOTP);
        setDataRole(resData.role);

        const tempCamps = (source: string) => {
          return Number(resData?.camps.find((i) => i.source === source)?.id);
        };

        const dataCamp = resData?.customers.map((val) => {
          switch (val.source) {
            case SourceType.FEED:
              setSelectedCustomerFeed(tempCamps(val.source) || null);
              setIsActiveSource((prevState) => ({ ...prevState, feed: val.isActive }));
              return {
                campId: tempCamps(val.source) || null,
                source: val.source,
                active: val.isActive,
              };
            case SourceType.FARM:
              setSelectedCustomerFarm(tempCamps(val.source) || null);
              setIsActiveSource((prevState) => ({ ...prevState, farm: val.isActive }));
              return {
                campId: tempCamps(val.source) || null,
                source: val.source,
                active: val.isActive,
              };
            case SourceType.VET:
              setSelectedCustomerVet(tempCamps(val.source) || null);
              setIsActiveSource((prevState) => ({ ...prevState, vet: val.isActive }));

              return {
                campId: tempCamps(val.source) || null,
                source: val.source,
                active: val.isActive,
              };
            case SourceType.LAB:
              setSelectedCustomerLab(tempCamps(val.source) || null);
              setIsActiveSource((prevState) => ({ ...prevState, lab: val.isActive }));
              return {
                campId: tempCamps(val.source) || null,
                source: val.source,
                active: val.isActive,
              };
            default:
              setSelectedCustomerCcu(tempCamps(val.source) || null);
              setIsActiveSource((prevState) => ({ ...prevState, ccu: val.isActive }));
              return {
                campId: tempCamps(val.source) || null,
                source: val.source,
                active: val.isActive,
              };
          }
        });
        setRequestCamp(dataCamp);
        setLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    //xử lý khi submit form
    if (!loading) {
      const dataCamp = requestCamps.map((val) => {
        switch (val.source) {
          case SourceType.FEED:
            return {
              campId: val.campId,
              source: val.source,
              active: isActiveSource.feed,
            };
          case SourceType.FARM:
            return {
              campId: val.campId,
              source: val.source,
              active: isActiveSource.farm,
            };
          case SourceType.VET:
            return {
              campId: val.campId,
              source: val.source,
              active: isActiveSource.vet,
            };
          case SourceType.LAB:
            return {
              campId: val.campId,
              source: val.source,
              active: isActiveSource.lab,
            };
          default:
            return {
              campId: val.campId,
              source: val.source,
              active: isActiveSource.ccu,
            };
        }
      });
      setRequestCamp(dataCamp);
    }

    //eslint-disable-next-line
  }, [isActiveSource]);

  // const onUpdateRequestCamp = (campId: number, source: string, dataAdded?: CampsModel) => {
  //   const idx = requestCamps.findIndex((item) => item.source === source);
  //   let data = [...requestCamps];

  //   if (idx !== -1) {
  //     if (!dataAdded) {
  //       // data = data.filter((i) => Number(i.campId) !== campId);
  //       data[idx] = {
  //         ...data[idx],
  //         campId: null,
  //       };
  //     } else {
  //       data[idx] = {
  //         ...data[idx],
  //         campId: campId,
  //         source: source,
  //       };
  //     }
  //   } else {
  //     if (!dataAdded) {
  //       return;
  //     }
  //     data.push({ campId, source });
  //   }

  //   setRequestCamp(data);
  // };

  // const handleUpdateCamp = (id: number, source: string, status: "add" | "delete") => {
  //   const result = dataFilterCamps(source)?.find((i) => i.id === id);
  //   if (status === "add") {
  //     onUpdateRequestCamp(id, source, result);
  //   } else if (status === "delete") {
  //     onUpdateRequestCamp(id, source);
  //   }
  // };

  const submitForm = async (values: any) => {
    const { requestFullName, requestPhoneNumber, requestEmail, permissionNote } = values;
    setLoading(true);
    if (!requestFullName) {
      enqueueSnackbar(t("NAME_NOT_ENTERED"), {
        variant: "error",
      });
      setLoading(false);
      return;
    }
    if (!requestEmail) {
      enqueueSnackbar(t("EMAIL_NOT_ENTERED"), {
        variant: "error",
      });
      setValid(true);
      setLoading(false);
      return;
    }
    if (!requestPhoneNumber) {
      enqueueSnackbar(t("PHONE_NOT_ENTERED"), {
        variant: "error",
      });
      setValid(true);
      setLoading(false);
      return;
    }

    if (requestCamps.findIndex((i) => !i.active && !!i.campId) > -1) {
      enqueueSnackbar("Vui lòng cấp quyền truy cập cho tài khoản được chọn", {
        variant: "error",
      });
      setLoading(false);
      return;
    }

    const validCamps = requestCamps.filter((item) => item.active);
    const dataCamp = validCamps.map((val) => {
      switch (val.source) {
        case SourceType.FEED:
          return {
            campId: val.campId,
            source: val.source,
          };
        case SourceType.FARM:
          return {
            campId: val.campId,
            source: val.source,
          };
        case SourceType.VET:
          return {
            campId: val.campId,
            source: val.source,
          };
        case SourceType.LAB:
          return {
            campId: val.campId,
            source: val.source,
          };
        default:
          return {
            campId: val.campId,
            source: val.source,
          };
      }
    });

    const data: SubAccountInputModel = {
      customerAccountId: Number(dataDetail?.parentAccount?.id),
      isDraft: ordDraft,
      requestEmail,
      requestPhoneNumber,
      requestFullName,
      requestSaleOfficeIds: deliveryAddress,
      requestRoleId: Number(dataRole?.id),
      requestCamps: dataCamp,
      code: "",
      status: dataDetail?.status,
      permissionNote,
    };

    CustomerService.updateSubAccount(accountId, data)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar(t("EDIT_ACCOUNT_SUCCESSFUL"), {
            variant: "success",
          });
          setLoading(false);
          history.push(APP_ROUTES.SUB_ACCOUNT_DETAIL.replace(":accountId", accountId.toString()));
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("EDIT_ACCOUNT_FAILED"), {
          variant: "error",
        });
        setLoading(false);
        setValid(true);
      });
  };

  // const dataFilterCamps = (source: string) => {
  //   const result: CampsModel[] = !!dataCustomer?.length
  //     ? dataCustomer?.find((i: CustomerAccount) => i.source === source)?.camps || []
  //     : [];
  //   return result;
  // };

  const dataSaleOffice =
    dataCustomer
      .map((cus) => {
        const mergedSalesOffices = cus.customerDivision.flatMap(
          (division) => division.salesOffices
        );
        return {
          salesOffices: mergedSalesOffices,
        };
      })
      .filter((item) => item !== null)
      .flatMap((item) => item.salesOffices) || [];

  const bindingDataForm = (data: SubAccountDetailModel) => {
    return {
      ...data,
      requestFullName: data.nickname || "",
      requestEmail: data.email || "",
      requestPhoneNumber: data.phoneNumber || "",
      role: dataRole?.position,
    };
  };

  return (
    <Dialog fullScreen open={true}>
      <div className="subAccount-modal">
        <SimpleModalHeader
          title={t("EDIT_CUSTOMER_ACCOUNT")}
          onClose={() => {
            history.push(APP_ROUTES.SUB_ACCOUNT_DETAIL.replace(":accountId", accountId.toString()));
          }}
        />
        {!loading ? (
          <div className="content">
            <Form
              onSubmit={async (values) => submitForm(values)}
              initialValues={!!dataDetail ? bindingDataForm(dataDetail) : null}>
              {({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit} id="account" style={{ width: "50%" }}>
                    {/* info */}
                    <div className="title">{t("ACCOUNT_INFO")}</div>
                    <div>
                      <div className="header">
                        <div className="source">{t("ACCESS_SCOPE")}</div>
                        {/* <div className="cus">{t("ACCOUNT")}</div> */}
                        <div className="access">{t("ACCESS_GRANT")}</div>
                      </div>
                      <div className="table">
                        <div className="col">
                          <div className="col-source">{t("FEED")}</div>
                          {/* <div className="col-cus">
                            {!selectedCustomerFeed ? (
                              <FormControl fullWidth>
                                <Select
                                  value={selectedCustomerFeed || undefined}
                                  onChange={(e) => {
                                    setSelectedCustomerFeed(Number(e.target.value));
                                    handleUpdateCamp(
                                      Number(e.target.value),
                                      SourceType.FEED,
                                      "add"
                                    );
                                  }}
                                  fullWidth
                                  variant="outlined"
                                  className="form-selected"
                                  renderValue={() => {
                                    return !!selectedCustomerFeed ? (
                                      dataFilterCamps(SourceType.FEED)?.find(
                                        (i) => i.id === selectedCustomerFeed
                                      )?.name
                                    ) : (
                                      <span
                                        style={{
                                          color: "#9e9e9e",
                                          display: "flex",
                                          alignItems: "center",
                                        }}>
                                        <SearchIcon
                                          style={{
                                            width: "2.2rem",
                                            height: "2.2rem",
                                            color: "#272B2F",
                                          }}
                                        />
                                        <span className="form-selected-placeholder">
                                          {t("SELECT_FARM_ACCOUNT")}
                                        </span>
                                      </span>
                                    );
                                  }}
                                  displayEmpty
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}>
                                  {dataFilterCamps(SourceType.FEED)?.map((tar, index: number) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={tar.id}
                                        style={{
                                          borderBottom: "0.1rem solid #EFEFEF",
                                        }}>
                                        {tar.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            ) : (
                              <div className="item-selected">
                                <div className="item-selected-body">
                                  <div className="name">
                                    {
                                      dataFilterCamps(SourceType.FEED)?.find(
                                        (i) => i.id === selectedCustomerFeed
                                      )?.name
                                    }
                                  </div>
                                  <div className="phone">
                                    {
                                      dataFilterCamps(SourceType.FEED)?.find(
                                        (i) => i.id === selectedCustomerFeed
                                      )?.phoneNumber
                                    }
                                  </div>

                                  <ClearIcon
                                    className="clear-icon"
                                    onClick={() => {
                                      setSelectedCustomerFeed(null);
                                      handleUpdateCamp(
                                        Number(selectedCustomerFeed),
                                        SourceType.FEED,
                                        "delete"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div> */}
                          <div className="col-access">
                            <Checkbox
                              className="checkbox_custom"
                              checked={isActiveSource.feed}
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  style={{
                                    fontSize: "2.2rem",
                                    color: !selectedCustomerFeed ? "#efefef" : "",
                                  }}
                                />
                              }
                              checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                              onChange={() =>
                                setIsActiveSource({
                                  feed: !isActiveSource.feed,
                                  farm: isActiveSource.farm,
                                  vet: isActiveSource.vet,
                                  lab: isActiveSource.lab,
                                  ccu: isActiveSource.ccu,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="col-source">{t("FARM")}</div>
                          {/* <div className="col-cus">
                            {!selectedCustomerFarm ? (
                              <FormControl fullWidth>
                                <Select
                                  value={selectedCustomerFarm || undefined}
                                  onChange={(e) => {
                                    setSelectedCustomerFarm(Number(e.target.value));
                                    handleUpdateCamp(
                                      Number(e.target.value),
                                      SourceType.FARM,
                                      "add"
                                    );
                                  }}
                                  fullWidth
                                  variant="outlined"
                                  className="form-selected"
                                  renderValue={() => {
                                    return !!selectedCustomerFarm ? (
                                      dataFilterCamps(SourceType.FARM)?.find(
                                        (i) => i.id === selectedCustomerFarm
                                      )?.name
                                    ) : (
                                      <span
                                        style={{
                                          color: "#9e9e9e",
                                          display: "flex",
                                          alignItems: "center",
                                        }}>
                                        <SearchIcon
                                          style={{
                                            width: "2.2rem",
                                            height: "2.2rem",
                                            color: "#272B2F",
                                          }}
                                        />
                                        <span className="form-selected-placeholder">
                                          {t("SELECT_FARM_ACCOUNT")}
                                        </span>
                                      </span>
                                    );
                                  }}
                                  displayEmpty
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}>
                                  {dataFilterCamps(SourceType.FARM)?.map((tar, index: number) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={tar.id}
                                        style={{
                                          borderBottom: "0.1rem solid #EFEFEF",
                                        }}>
                                        {tar.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            ) : (
                              <div className="item-selected">
                                <div className="item-selected-body">
                                  <div className="name">
                                    {
                                      dataFilterCamps(SourceType.FARM)?.find(
                                        (i) => i.id === selectedCustomerFarm
                                      )?.name
                                    }
                                  </div>
                                  <div className="phone">
                                    {
                                      dataFilterCamps(SourceType.FARM)?.find(
                                        (i) => i.id === selectedCustomerFarm
                                      )?.phoneNumber
                                    }
                                  </div>

                                  <ClearIcon
                                    className="clear-icon"
                                    onClick={() => {
                                      setSelectedCustomerFarm(null);
                                      handleUpdateCamp(
                                        Number(selectedCustomerFarm),
                                        SourceType.FARM,
                                        "delete"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div> */}
                          <div className="col-access">
                            <Checkbox
                              className="checkbox_custom"
                              checked={isActiveSource.farm}
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  style={{
                                    fontSize: "2.2rem",
                                    color: !selectedCustomerFarm ? "#efefef" : "",
                                  }}
                                />
                              }
                              checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                              onChange={() =>
                                setIsActiveSource({
                                  feed: isActiveSource.feed,
                                  farm: !isActiveSource.farm,
                                  vet: isActiveSource.vet,
                                  lab: isActiveSource.lab,
                                  ccu: isActiveSource.ccu,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="col-source">{t("MEDICINE_INDUSTRY")}</div>
                          {/* <div className="col-cus">
                            {!selectedCustomerVet ? (
                              <FormControl fullWidth>
                                <Select
                                  value={selectedCustomerVet || undefined}
                                  onChange={(e) => {
                                    setSelectedCustomerVet(Number(e.target.value));
                                    handleUpdateCamp(Number(e.target.value), SourceType.VET, "add");
                                  }}
                                  fullWidth
                                  variant="outlined"
                                  className="form-selected"
                                  renderValue={() => {
                                    return !!selectedCustomerVet ? (
                                      dataFilterCamps(SourceType.VET)?.find(
                                        (i) => i.id === selectedCustomerVet
                                      )?.name
                                    ) : (
                                      <span
                                        style={{
                                          color: "#9e9e9e",
                                          display: "flex",
                                          alignItems: "center",
                                        }}>
                                        <SearchIcon
                                          style={{
                                            width: "2.2rem",
                                            height: "2.2rem",
                                            color: "#272B2F",
                                          }}
                                        />
                                        <span className="form-selected-placeholder">
                                          {t("SELECT_FARM_ACCOUNT")}
                                        </span>
                                      </span>
                                    );
                                  }}
                                  displayEmpty
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}>
                                  {dataFilterCamps(SourceType.VET)?.map((tar, index: number) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={tar.id}
                                        style={{
                                          borderBottom: "0.1rem solid #EFEFEF",
                                        }}>
                                        {tar.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            ) : (
                              <div className="item-selected">
                                <div className="item-selected-body">
                                  <div className="name">
                                    {
                                      dataFilterCamps(SourceType.VET)?.find(
                                        (i) => i.id === selectedCustomerVet
                                      )?.name
                                    }
                                  </div>
                                  <div className="phone">
                                    {
                                      dataFilterCamps(SourceType.VET)?.find(
                                        (i) => i.id === selectedCustomerVet
                                      )?.phoneNumber
                                    }
                                  </div>

                                  <ClearIcon
                                    className="clear-icon"
                                    onClick={() => {
                                      setSelectedCustomerVet(null);
                                      handleUpdateCamp(
                                        Number(selectedCustomerVet),
                                        SourceType.VET,
                                        "delete"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div> */}
                          <div className="col-access">
                            <Checkbox
                              className="checkbox_custom"
                              checked={isActiveSource.vet}
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  style={{
                                    fontSize: "2.2rem",
                                    color: !selectedCustomerVet ? "#efefef" : "",
                                  }}
                                />
                              }
                              checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                              onChange={() =>
                                setIsActiveSource({
                                  feed: isActiveSource.feed,
                                  farm: isActiveSource.farm,
                                  vet: !isActiveSource.vet,
                                  lab: isActiveSource.lab,
                                  ccu: isActiveSource.ccu,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="col-source">{t("BIOLAB_INDUSTRY")}</div>
                          {/* <div className="col-cus">
                            {!selectedCustomerLab ? (
                              <FormControl fullWidth>
                                <Select
                                  value={selectedCustomerLab || undefined}
                                  onChange={(e) => {
                                    setSelectedCustomerLab(Number(e.target.value));
                                    handleUpdateCamp(Number(e.target.value), SourceType.LAB, "add");
                                  }}
                                  fullWidth
                                  variant="outlined"
                                  className="form-selected"
                                  renderValue={() => {
                                    return !!selectedCustomerLab ? (
                                      dataFilterCamps(SourceType.LAB)?.find(
                                        (i) => i.id === selectedCustomerLab
                                      )?.name
                                    ) : (
                                      <span
                                        style={{
                                          color: "#9e9e9e",
                                          display: "flex",
                                          alignItems: "center",
                                        }}>
                                        <SearchIcon
                                          style={{
                                            width: "2.2rem",
                                            height: "2.2rem",
                                            color: "#272B2F",
                                          }}
                                        />
                                        <span className="form-selected-placeholder">
                                          {t("SELECT_FARM_ACCOUNT")}
                                        </span>
                                      </span>
                                    );
                                  }}
                                  displayEmpty
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}>
                                  {dataFilterCamps(SourceType.LAB)?.map((tar, index: number) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={tar.id}
                                        style={{
                                          borderBottom: "0.1rem solid #EFEFEF",
                                        }}>
                                        {tar.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            ) : (
                              <div className="item-selected">
                                <div className="item-selected-body">
                                  <div className="name">
                                    {
                                      dataFilterCamps(SourceType.LAB)?.find(
                                        (i) => i.id === selectedCustomerLab
                                      )?.name
                                    }
                                  </div>
                                  <div className="phone">
                                    {
                                      dataFilterCamps(SourceType.LAB)?.find(
                                        (i) => i.id === selectedCustomerLab
                                      )?.phoneNumber
                                    }
                                  </div>

                                  <ClearIcon
                                    className="clear-icon"
                                    onClick={() => {
                                      setSelectedCustomerLab(null);
                                      handleUpdateCamp(
                                        Number(selectedCustomerLab),
                                        SourceType.LAB,
                                        "delete"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div> */}
                          <div className="col-access">
                            <Checkbox
                              className="checkbox_custom"
                              checked={isActiveSource.lab}
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  style={{
                                    fontSize: "2.2rem",
                                    color: !selectedCustomerLab ? "#efefef" : "",
                                  }}
                                />
                              }
                              checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                              onChange={() =>
                                setIsActiveSource({
                                  feed: isActiveSource.feed,
                                  farm: isActiveSource.farm,
                                  vet: isActiveSource.vet,
                                  lab: !isActiveSource.lab,
                                  ccu: isActiveSource.ccu,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="col-source">{t("SUPPLY_CHAIN")}</div>
                          {/* <div className="col-cus">
                            {!selectedCustomerCcu ? (
                              <FormControl fullWidth>
                                <Select
                                  value={selectedCustomerCcu || undefined}
                                  onChange={(e) => {
                                    setSelectedCustomerCcu(Number(e.target.value));
                                    handleUpdateCamp(Number(e.target.value), SourceType.CCU, "add");
                                  }}
                                  fullWidth
                                  variant="outlined"
                                  className="form-selected"
                                  renderValue={() => {
                                    return !!selectedCustomerCcu ? (
                                      dataFilterCamps(SourceType.CCU)?.find(
                                        (i) => i.id === selectedCustomerCcu
                                      )?.name
                                    ) : (
                                      <span
                                        style={{
                                          color: "#9e9e9e",
                                          display: "flex",
                                          alignItems: "center",
                                        }}>
                                        <SearchIcon
                                          style={{
                                            width: "2.2rem",
                                            height: "2.2rem",
                                            color: "#272B2F",
                                          }}
                                        />
                                        <span className="form-selected-placeholder">
                                          {t("SELECT_FARM_ACCOUNT")}
                                        </span>
                                      </span>
                                    );
                                  }}
                                  displayEmpty
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}>
                                  {dataFilterCamps(SourceType.CCU)?.map((tar, index: number) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={tar.id}
                                        style={{
                                          borderBottom: "0.1rem solid #EFEFEF",
                                        }}>
                                        {tar.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            ) : (
                              <div className="item-selected">
                                <div className="item-selected-body">
                                  <div className="name">
                                    {
                                      dataFilterCamps(SourceType.CCU)?.find(
                                        (i) => i.id === selectedCustomerCcu
                                      )?.name
                                    }
                                  </div>
                                  <div className="phone">
                                    {
                                      dataFilterCamps(SourceType.CCU)?.find(
                                        (i) => i.id === selectedCustomerCcu
                                      )?.phoneNumber
                                    }
                                  </div>

                                  <ClearIcon
                                    className="clear-icon"
                                    onClick={() => {
                                      setSelectedCustomerCcu(null);
                                      handleUpdateCamp(
                                        Number(selectedCustomerCcu),
                                        SourceType.CCU,
                                        "delete"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div> */}
                          <div className="col-access">
                            <Checkbox
                              className="checkbox_custom"
                              checked={isActiveSource.ccu}
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  style={{
                                    fontSize: "2.2rem",
                                    color: !selectedCustomerCcu ? "#efefef" : "",
                                  }}
                                />
                              }
                              checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                              onChange={() =>
                                setIsActiveSource({
                                  feed: isActiveSource.feed,
                                  farm: isActiveSource.farm,
                                  vet: isActiveSource.vet,
                                  lab: isActiveSource.lab,
                                  ccu: !isActiveSource.ccu,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* name */}
                    <div className="title">{t("LOGIN_NAME")}</div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ width: "100%", marginRight: "3.5rem" }}>
                        <div className="form-item">
                          <div className="form-label">{t("DISPLAY_NAME")}</div>
                          <Field
                            name="requestFullName"
                            validate={FormUtil.Rule.required(
                              t("INPUT_BY_NAME", { NAME: t("DISPLAY_NAME").toLowerCase() })
                            )}>
                            {({ input, meta, ...rest }) => {
                              return (
                                <TextField
                                  {...input}
                                  {...rest}
                                  placeholder={t("INPUT_BY_NAME", {
                                    NAME: t("DISPLAY_NAME").toLowerCase(),
                                  })}
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                />
                              );
                            }}
                          </Field>
                        </div>

                        <div className="form-item">
                          <div className="form-label">{t("LOGIN_EMAIL")}</div>
                          <Field
                            name="requestEmail"
                            validate={FormUtil.Rule.required(
                              t("INPUT_BY_NAME", {
                                NAME: t("LOGIN_EMAIL").toLowerCase(),
                              })
                            )}>
                            {({ input, meta, ...rest }) => {
                              return (
                                <TextField
                                  {...input}
                                  {...rest}
                                  placeholder={t("INPUT_BY_NAME", {
                                    NAME: t("LOGIN_EMAIL").toLowerCase(),
                                  })}
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                />
                              );
                            }}
                          </Field>
                        </div>
                      </div>
                      <div style={{ width: "100%", marginLeft: "3.5rem" }}>
                        <div className="form-item">
                          <div className="form-label">{t("LOGIN_PHONE")}</div>
                          <Field
                            name="requestPhoneNumber"
                            validate={
                              requireOTP
                                ? FormUtil.composeValidators([
                                    FormUtil.Rule.pattern(REGEX.NUMBER_CHARACTER, {
                                      errorMessage: t("INVALID_NAME", {
                                        NAME: t("LOGIN_PHONE").toLowerCase(),
                                      }),
                                    }),
                                  ])
                                : undefined
                            }>
                            {({ input, meta, ...rest }) => {
                              return (
                                <TextField
                                  {...input}
                                  {...rest}
                                  placeholder={t("INPUT_BY_NAME", {
                                    NAME: t("LOGIN_PHONE").toLowerCase(),
                                  })}
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={(valid && !input.value) || (meta.error && meta.touched)}
                                />
                              );
                            }}
                          </Field>
                        </div>
                        <div className="form-item select-multiple">
                          <div className="form-label">{t("DELIVERY_ADDRESS")}</div>
                          <FormControl fullWidth>
                            <Select
                              value={deliveryAddress}
                              multiple
                              onChange={(e) => {
                                const value: any = e.target.value;
                                setDeliveryAddress(value);
                              }}
                              fullWidth
                              variant="outlined"
                              className="form-selected"
                              renderValue={() => {
                                const resultData = deliveryAddress
                                  .map((item) => {
                                    return dataSaleOffice.filter((i) => i.id === item);
                                  })
                                  .flatMap((i) => i);

                                return !!deliveryAddress.length ? (
                                  <span className="input-multi">
                                    {resultData.map((val) => {
                                      return (
                                        <span key={val.id} className="input-multi-item">
                                          {val.description}
                                        </span>
                                      );
                                    })}
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      color: "#9e9e9e",
                                      display: "flex",
                                      alignItems: "center",
                                    }}>
                                    <span className="form-selected-placeholder">
                                      {t("SELECT_BY_NAME", {
                                        NAME: t("DELIVERY_ADDRESS").toLowerCase(),
                                      })}
                                    </span>
                                  </span>
                                );
                              }}
                              displayEmpty
                              MenuProps={{
                                className: "select-menu-list",
                              }}>
                              {!!dataSaleOffice &&
                                !!dataSaleOffice.length &&
                                dataSaleOffice?.map((tar, index: number) => {
                                  const active = !!deliveryAddress.length
                                    ? deliveryAddress.findIndex((i) => i === tar.id) > -1
                                    : false;

                                  return (
                                    <MenuItem
                                      key={index}
                                      value={tar?.id}
                                      style={{
                                        borderBottom: "0.1rem solid #EFEFEF",
                                        background: active ? "#d4d4d4 !important" : "#fff",
                                      }}>
                                      {tar?.description}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="form-item">
                      <div className="form-label">{t("ROLE")}</div>
                      <Field name="role">
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              placeholder={t("INPUT_BY_NAME", {
                                NAME: t("ROLE").toLowerCase(),
                              })}
                              color="success"
                              disabled
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">{t("NOTES")}</div>
                      <Field
                        name="permissionNote"
                        validate={FormUtil.Rule.required(
                          t("INPUT_BY_NAME", {
                            NAME: t("NOTES").toLowerCase(),
                          })
                        )}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              placeholder={t("INPUT_BY_NAME", {
                                NAME: t("NOTES").toLowerCase(),
                              })}
                              color="success"
                              className="form-textarea-field"
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                              multiline
                              rows={3}
                              fullWidth
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="otp">
                        <Checkbox
                          className="checkbox_custom"
                          checked={requireOTP}
                          onChange={() => setRequireOTP(!requireOTP)}
                        />
                        <div className="otp-text">{t("OTP_VERIFICATION")}</div>
                      </div>
                    </div>
                    <div className="footer">
                      <div className="show">
                        <div
                          className="btn-cancel-style btn__text-cancel"
                          onClick={() => {
                            history.push(APP_ROUTES.SUB_ACCOUNT);
                          }}>
                          {t("CANCEL")}
                        </div>
                        <button
                          onClick={() => {
                            !loading && setOrdDraft(true);
                          }}
                          disabled={loading}
                          type="submit"
                          ref={submitRef}
                          className="btn-confirm-style btn-border btn--transparent"
                          style={{ marginLeft: "4rem" }}>
                          {t("SAVE_DRAFT")}
                        </button>
                        <button
                          onClick={() => {}}
                          disabled={loading}
                          type="submit"
                          ref={submitRef}
                          className="btn-confirm-style"
                          style={{ marginLeft: "4rem" }}>
                          {t("SAVE")}
                        </button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Form>
          </div>
        ) : (
          <Loading style={{ margin: "2rem 0", height: "60vh" }} />
        )}
      </div>
    </Dialog>
  );
};

export default UpdateSubAccountModal;
