import Loading from "@Components/Loading";
import useProductSelect from "@Hooks/useProductSelect";
import {
  Add as AddIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Clear as ClearIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { preventCharacter } from "@Utils";
import { useMemo, useState } from "react";
import "./index.scss";

export type P_Props = {
  orderLabel: number;
  data: any;
  onChange: (data: any[]) => void;
  onRemoveOrder: () => void;
  checkWeekUpdate: boolean;
};

const OrderPlan: React.FC<P_Props> = (props) => {
  const { orderLabel, data, onChange, onRemoveOrder, checkWeekUpdate } = props;
  const { data: dataProduct, loading } = useProductSelect();

  const [defaultData, setDefaultData] = useState<any>([]);

  useMemo(() => {
    setDefaultData(data?.items);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddFields = () => {
    setDefaultData([...defaultData, { itemId: 0, quantity: 1 }]);

    onChange([...defaultData, { itemId: 0, quantity: 1 }]);
  };

  const onUpdateProduct = (index: number, name: string, value: any) => {
    const values = [...defaultData];

    values[index][name] = value;

    if (name === "itemId") {
      let product = dataProduct?.find((f) => f.item.id === value);

      values[index]["divisionId"] = product?.item.division.id;
      values[index]["unitPrice"] = product?.value;
      values[index]["saleUnit"] = product?.baseUoM;
      values[index]["dimension"] = Number(product?.item.dimension) * values[index]["quantity"];
      values[index]["name"] = product?.item.name;
      values[index]["baseUoM"] = product?.item.baseUoM;
      values[index]["code"] = product?.item.code;

      if (product?.value) values[index]["subTotal"] = product?.value * values[index]["quantity"];
    }

    setDefaultData(values);

    onChange(defaultData);
  };

  const handleRemoveFields = (index: number) => {
    const values = [...defaultData];
    values.splice(index, 1);

    setDefaultData(values);

    onChange(values);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onChange(defaultData);
  };

  let productSelect = (defaultData && defaultData?.map((pro: any) => pro.itemId)) || [];

  return (
    <Accordion key={data?.id} className="order-plan" defaultExpanded>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel1-content"
        id="panel1-header">
        <div>Đơn {orderLabel}</div>
        {checkWeekUpdate && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              onRemoveOrder();
            }}>
            <ClearIcon style={{ fontSize: 20, color: "#5A5959", cursor: "pointer" }} />
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails className="order-plan__grid">
        <Grid container spacing={3} className="order-plan__grid__label">
          <Grid item xs={6} style={{ marginBottom: 12 }}>
            Sản phẩm
          </Grid>
          <Grid item xs={6} style={{ marginBottom: 12 }}>
            Sản lượng
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit}>
          {defaultData?.map((val: any, index: number) => (
            <Grid container key={index} spacing={3}>
              <Grid item xs={6} style={{ marginBottom: 12 }}>
                <Autocomplete
                  value={val}
                  onChange={(event, newValue) => {
                    let itemId = dataProduct?.find((f) => f.item.id === newValue.id)?.item.id;
                    onUpdateProduct(index, "itemId", itemId);
                  }}
                  disableClearable
                  clearIcon={false}
                  options={dataProduct
                    ?.filter((f) => !productSelect?.includes(f.item.id))
                    ?.map((option) => {
                      return {
                        name: option.item.name,
                        id: option.item.id,
                        code: option.item.code,
                      };
                    })}
                  noOptionsText={
                    (loading && <Loading />) ||
                    (dataProduct?.filter((f) => !productSelect?.includes(f.item.id)).length === 0 &&
                      "Không có dữ liệu")
                  }
                  getOptionLabel={(option) => {
                    return !!option.name || !!option.code
                      ? `${option.code} - ${option.name}`
                      : "Chọn sản phẩm";
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          className: "input",
                        }}
                        placeholder="Chọn sản phẩm"
                        variant="outlined"
                        className="form-text-field"
                      />
                    );
                  }}
                  ListboxProps={{
                    style: {
                      maxHeight: "200px",
                      overflow: "auto",
                      fontFamily: "SVN-Gotham",
                      fontSize: "14px",
                    },
                  }}
                  className="autocomplete-field"
                  disabled={!checkWeekUpdate}
                />
              </Grid>
              <Grid
                item
                xs={6}
                container
                style={{ marginBottom: 12 }}
                justifyContent={"space-between"}
                alignItems={"center"}>
                <TextField
                  placeholder="Nhập sản lượng"
                  color="success"
                  className="form-text-field"
                  inputProps={{ maxLength: 5, className: "input" }}
                  name="quantity"
                  defaultValue={val.quantity}
                  onBlur={(e) => {
                    if (!e.target.value || val.quantity <= 0) onUpdateProduct(index, "quantity", 1);
                    else onUpdateProduct(index, "quantity", +e.target.value);
                  }}
                  style={{ width: "94%" }}
                  disabled={!checkWeekUpdate}
                  onKeyDown={preventCharacter}
                />
                {checkWeekUpdate && defaultData?.length > 1 && (
                  <div onClick={() => handleRemoveFields(index)}>
                    <DeleteOutlineOutlinedIcon
                      style={{ fontSize: 20, color: "#5A5959", cursor: "pointer" }}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          ))}
        </form>
        {checkWeekUpdate && (
          <div className="order-plan__addRow" onClick={() => handleAddFields()}>
            <AddIcon style={{ fontSize: "1.8rem", marginRight: "1rem" }} /> Thêm sản phẩm
          </div>
        )}
        <Divider />
        <div className="order-plan__totalRow">
          <div>Tổng sản lượng theo ngày</div>
          <div className="total">
            {data?.items?.reduce((sum: any, item: any) => {
              return sum + item.quantity;
            }, 0)}
            &#160;kg
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default OrderPlan;
