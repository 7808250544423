/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import ImgEmpty from "@Assets/images/empty.png";
import Search from "@Assets/images/icons/img-search.png";
import CountStatus from "@Components/CountStatus";
import EmptyList from "@Components/EmptyList";
import FloatingBtn from "@Components/FloatingBtn";
import Loading from "@Components/Loading";
import RangePicker from "@Components/RangePicker";
import { APP_ROUTES, StatusPlan, TEXT_HOLDER } from "@Constants";
import useDateRange from "@Hooks/useDateRange";
import { Action, FetchAction, OrderPlanPermission, PlanOutputModel, PlanStatus } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { Search as SearchIcon } from "@mui/icons-material";
import { InputAdornment, TextField, Tooltip } from "@mui/material";
import { CustomerService } from "@Services";
import { PlanService } from "@Services/plan.service";
import { dateFormat, PermissionUtil, StatusUIUtil } from "@Utils";
import _ from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useLocation } from "react-router-dom";
import "./index.scss";

function reducer(state: PaginateFetchReducer<PlanOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

const Plan: React.FC = () => {
  const { t } = useTranslation(["translation", "order"]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const location = useLocation<any>();
  const { enqueueSnackbar } = useSnackbar();
  const [range, setRange, startDate] = useDateRange(true);
  const [selectTab, setSelectTab] = useState<string>("ALL");
  const history = useHistory();
  const [profile, setProfile] = useState<any>([]);

  const loadProfileData = _.debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  useEffect(() => {
    loadProfileData();
    //eslint-disable-next-line
  }, []);

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  const mounted = useRef(false);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await PlanService.getAllPlan(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 50,
        status: selectTab,
        strSearch: searchValue,
        from: range.startTime?.format("YYYY-MM-DD"),
        to: range.endTime?.format("YYYY-MM-DD"),
      },
      true
    );
  }, [selectTab, searchValue, range.startTime, range.endTime]);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  useMemo(() => {
    if (location.state?.startTime !== undefined) {
      setRange(moment(location.state?.startTime), moment(location.state?.endTime));
    } else {
      setRange(
        moment(startDate).set({ hours: 0, minutes: 0, seconds: 0 }),
        moment(startDate).add(6, "days").set({ hours: 23, minutes: 59, seconds: 59 })
      );
    }

    if (location.state?.status !== undefined) {
      setSelectTab(location.state?.status);
    } else {
      setSelectTab("ALL");
    }
  }, [location]);

  const renderReason = (data: PlanOutputModel) => {
    switch (data.status) {
      case PlanStatus.CHANGING:
        return data.changingReason || TEXT_HOLDER;
      case PlanStatus.REJECTED:
        return data.rejectedReason || TEXT_HOLDER;
      case PlanStatus.CLOSED:
        return data.closedReason || TEXT_HOLDER;
      default:
        return TEXT_HOLDER;
    }
  };

  const checkSelectTab =
    selectTab === "ALL" ||
    selectTab === PlanStatus.CHANGING ||
    selectTab === PlanStatus.REJECTED ||
    selectTab === PlanStatus.CLOSED;

  //permission
  const checkPermissionPlanCreate = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPlanPermission.MANAGE_ORDERPLAN_ADD,
    true
  );
  const checkPermissionPlanDetail = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPlanPermission.MANAGE_ORDERPLAN_VIEWDETAILS,
    true
  );

  return (
    <div className="plan">
      <div className="plan-content">
        <div className="plan__header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              placeholder={t("ENTER_SEARCH")}
              variant="outlined"
              className="search-style"
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" className="icon" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            <RangePicker
              startDate={
                location.state?.startTime !== undefined
                  ? moment(location.state?.startTime)
                  : range.startTime
              }
              endDate={
                location.state?.endTime !== undefined
                  ? moment(location.state?.endTime)
                  : range.endTime
              }
              onChangeDate={(val) => {
                if (val[0] && val[1]) {
                  setRange(
                    moment(val[0]).set({ hours: 0, minutes: 0, seconds: 0 }),
                    moment(val[1]).set({ hours: 23, minutes: 59, seconds: 59 })
                  );
                }
              }}
            />
          </div>
        </div>
        {/* <div className="plan__nav-header">
          {StatusPlan.map((o) => {
            return (
              <div
                key={o.id}
                className={
                  o.value === selectTab
                    ? "tab tab-active btn--shiny"
                    : `tab ${o?.value?.toLocaleLowerCase()} tab-hover`
                }
                onClick={() => {
                  setSelectTab(o.value);
                }}>
                {t(o.label)}
                <span>{state.count?.[`${o.value}`]}</span>
              </div>
            );
          })}
        </div> */}
        <CountStatus
          data={StatusPlan}
          count={state.count}
          onChange={(val) => setSelectTab(val)}
          toUpperCase
        />
        {state.loading ? (
          <Loading style={{ margin: "2rem 0", height: "60vh" }} />
        ) : (
          <>
            {state.data ? (
              state.data.length > 0 ? (
                <div className="plan__list">
                  <div className="header">
                    <div className="id">ID</div>
                    <div className="name">Tên kế hoạch</div>
                    <div className="des" style={{ width: checkSelectTab ? "20%" : "36%" }}>
                      Mô tả
                    </div>
                    {checkSelectTab && <div className="reason">Lý do</div>}
                    <div className="startTime">Thời gian bắt đầu</div>
                    <div className="endTime">Thời gian kết thúc</div>
                    <div className="status">Trạng thái</div>
                  </div>
                  <InfiniteScroll
                    useWindow={false}
                    pageStart={0}
                    initialLoad={false}
                    hasMore={hasMore}
                    loadMore={() =>
                      fetchData({
                        limit: 50,
                        page: state.page + 1,
                        status: selectTab,
                        strSearch: searchValue,
                        from: range.startTime?.format("YYYY-MM-DD"),
                        to: range.endTime?.format("YYYY-MM-DD"),
                      })
                    }>
                    {state.data.map((plan: PlanOutputModel) => {
                      return (
                        <div
                          key={plan.id}
                          className="item"
                          onClick={() => {
                            checkPermissionPlanDetail
                              ? history.push({
                                  pathname:
                                    plan.status === PlanStatus.OPENING
                                      ? APP_ROUTES.DRAFT_PLAN.replace(
                                          ":planId",
                                          plan.id?.toString()
                                        )
                                      : APP_ROUTES.PLAN_DETAIL.replace(
                                          ":planId",
                                          plan.id?.toString()
                                        ),
                                  state: {
                                    startTime: range.startTime.toISOString(),
                                    endTime: range.endTime.toISOString(),
                                    status: selectTab,
                                  },
                                })
                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                  preventDuplicate: true,
                                  variant: "error",
                                });
                          }}>
                          <div className="id">{plan.code}</div>
                          <div className="name">{plan.name}</div>
                          <div className="des" style={{ width: checkSelectTab ? "20%" : "36%" }}>
                            <Tooltip title={plan.description || TEXT_HOLDER} placement="top-start">
                              <span>{plan.description || TEXT_HOLDER}</span>
                            </Tooltip>
                          </div>
                          {checkSelectTab && (
                            <div className="reason ellipsis">
                              <Tooltip title={renderReason(plan)} placement="top-start">
                                <span>{renderReason(plan) || TEXT_HOLDER}</span>
                              </Tooltip>
                            </div>
                          )}
                          <div className="startTime">
                            {dateFormat(plan.start, false, "DD/MM/YYYY")}
                          </div>
                          <div className="endTime">{dateFormat(plan.end, false, "DD/MM/YYYY")}</div>
                          <div className="status">{StatusUIUtil.renderStatusPlan(plan.status)}</div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              ) : visibleSearch ? (
                <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
              ) : (
                <EmptyList img={ImgEmpty} title={t("EMPTY_LIST")} />
              )
            ) : (
              <EmptyList img={ImgEmpty} title={t("EMPTY_LIST")} />
            )}
          </>
        )}
      </div>
      {checkPermissionPlanCreate && (
        <FloatingBtn
          visible={false}
          onClickPrimary={() => {
            history.push(APP_ROUTES.CREATE_PLAN);
          }}
          btns={[]}
        />
      )}
    </div>
  );
};

export default Plan;
