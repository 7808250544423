/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
import Search from "@Assets/images/icons/img-search.png";
import Ord from "@Assets/images/ordnull.png";
import ComingSoon from "@Components/CommingSoon";
import CountStatus from "@Components/CountStatus";
import EmptyList from "@Components/EmptyList";
import FloatingBtn from "@Components/FloatingBtn";
import { WeightIcon } from "@Components/Icons/WeightIcon";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import RangePicker from "@Components/RangePicker";
import {
  APP_ROUTES,
  NavHeaderOrder,
  NavHeaderOrderFarm,
  NavHeaderOrderLab,
  TEXT_ASTERISK,
  TEXT_HOLDER,
  TIME,
} from "@Constants";
import useDateRange from "@Hooks/useDateRange";
import {
  Action,
  FetchAction,
  ItemPermission,
  OrderOutputModel,
  OrderPermission,
  OrderStatus,
  Orders,
  PaginateFetchReducer,
  SourceType,
} from "@Models";
import { CustomerService, OrderService } from "@Services";
import { PermissionUtil, StatusUIUtil, currencyFormat } from "@Utils";
import {
  Clear as ClearIcon,
  ClearRounded as ClearRoundedIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Chip, Dialog, InputAdornment, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import _ from "lodash";
import _debounce from "lodash/debounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { DEFAULT_SIZE } from "../Customer/LeftNavigation";
import "./index.scss";

type P_Props = {
  sourceType: string;
};

function reducer(state: PaginateFetchReducer<OrderOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        loading: false,
        data: [...state.data, ...action.payload.data],
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        loading: false,
        data: action.payload.data,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };

    default:
      return { ...state };
  }
}

const OrderListing: React.FC<P_Props> = ({ sourceType }) => {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [range, setRange] = useDateRange();
  const [selectStatus, setSelectStatus] = useState<string>("all");
  const [selectSubStatus, setSelectSubStatus] = useState<string>("all");
  const [comingsoon, setComingsoon] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const mounted = useRef(false);
  const location = useLocation<any>();
  const [getOrder, setGetOrder] = useState<boolean>(false);
  const [notiActivated, setNotiActivated] = useState<{
    message: string;
    status: boolean;
  }>({
    message: "",
    status: false,
  });
  const [profile, setProfile] = useState<any>([]);
  const [notiCheckCusDiv, setNotiCheckCusDiv] = useState<boolean>(false);
  const [todayBtn, setTodayBtn] = useState<boolean>(false);

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "1.3rem",
            lineHeight: "1.9rem",
            color: " #FFFFFF",
            backgroundColor: "#312F30",
            fontFamily: "SVN-Gotham",
            fontWeight: 400,
            boxShadow: "0rem 0.4rem 1.2rem rgba(111, 125, 145, 0.16)",
            borderRadius: "0.4rem",
          },
        },
      },
    },
  });

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    count: {},
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await OrderService.filterOrders(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err: any) {
      if (err.response?.status === 401) {
        if (err.response?.data.message === "SESSION_EXPIRED") {
          setNotiActivated({ message: "SESSION_EXPIRED", status: true });
        } else {
          setNotiActivated({ message: "UNAUTHORIZED", status: true });
        }
      }
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  const checkSourceLab = sourceType === SourceType.LAB;
  const checkSourceFeed = sourceType === SourceType.FEED;

  const loadProfileData = _debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  const dataCustomer = profile.customer?.find((cus: any) => cus.source === SourceType.LAB);

  useEffect(() => {
    loadProfileData();
  }, [sourceType]);

  const checkCustomerDiv =
    profile?.customer?.find((customerDiv: any) => customerDiv?.source === sourceType)
      ?.customerDivision?.length > 0;

  useEffect(() => {
    setGetOrder(true);
    if (location.state?.startTime !== undefined) {
      setRange(moment(location.state?.startTime), moment(location.state?.endTime));
    } else {
      setRange(
        moment().subtract(7, "days").set({ hours: 0, minutes: 0, seconds: 0 }),
        moment().set({ hours: 23, minutes: 59, seconds: 59 })
      );
    }
  }, [location]);

  useEffect(() => {
    getOrder &&
      range.startTime.toISOString() !== null &&
      range.endTime.toISOString() !== null &&
      fetchData(
        {
          page: 1,
          limit: 10,
          from: range.startTime.toISOString(),
          to: range.endTime.toISOString(),
          strSearch: searchValue,
          source: sourceType,
          status: selectStatus,
          subStatus: selectSubStatus === "isFeedBack" ? "all" : undefined,
          isFeedBack: selectSubStatus === "isFeedBack" ? true : undefined,
        },
        true
      );
  }, [
    range.startTime.toISOString(),
    range.endTime.toISOString(),
    searchValue,
    sourceType,
    selectStatus,
    selectSubStatus,
  ]);

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 300);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  useEffect(() => {
    location.state?.status !== undefined
      ? setSelectStatus(location.state?.status)
      : setSelectStatus("all");
  }, []);

  const checkPermissionOrderCreate = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_ADD,
    true
  );
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );
  const checkPermissionOrderDetail = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_VIEWDETAILS,
    true
  );
  
  return (
    <div className="order-listing">
      <div className="content">
        <div className="header">
          <div className="left">
            <TextField
              placeholder={t("SEARCH_CUSTOMER_CODE_NAME_PHONE")}
              variant="outlined"
              className={"search-style"}
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            <RangePicker
              startDate={
                location.state?.startTime !== undefined
                  ? moment(location.state?.startTime)
                  : range.startTime
              }
              endDate={
                location.state?.endTime !== undefined
                  ? moment(location.state?.endTime)
                  : range.endTime
              }
              today={todayBtn}
              onChangeDate={(val) => {
                if (val[0] && val[1]) {
                  setRange(
                    moment(val[0]).set({ hours: 0, minutes: 0, seconds: 0 }),
                    moment(val[1]).set({ hours: 23, minutes: 59, seconds: 59 })
                  );
                }
              }}
            />
            <button
              className="btn-white"
              onClick={() => {
                setTodayBtn(true);
                setRange(
                  moment().set({ hours: 0, minutes: 0, seconds: 0 }),
                  moment().set({ hours: 23, minutes: 59, seconds: 59 })
                );
              }}>
              {t("TODAY")}
            </button>
          </div>
        </div>
        {state.loading === true ? (
          <Loading />
        ) : (
          <>
            {/* <div className="nav-header">
              {(sourceType === SourceType.FARM
                ? NavHeaderOrderFarm
                : sourceType === SourceType.LAB
                ? NavHeaderOrderLab
                : NavHeaderOrder
              ).map((o) => {
                return (
                  <div
                    key={o.id}
                    className={
                      o.value === selectStatus
                        ? "tab tab-active btn--shiny"
                        : `tab ${o.value} tab-hover`
                    }
                    onClick={() => {
                      setSelectStatus(o.value);
                    }}>
                    {t(o.label)} <span>{state.count[`${o.value}`]}</span>
                  </div>
                );
              })}
            </div> */}
            <CountStatus
              data={
                sourceType === SourceType.FARM
                  ? NavHeaderOrderFarm
                  : sourceType === SourceType.LAB
                  ? NavHeaderOrderLab
                  : NavHeaderOrder
              }
              count={state.count}
              onChange={(val) => setSelectStatus(val)}
            />
            {state.data ? (
              <div className="list">
                {checkSourceLab ? (
                  <div className="list-header-lab">
                    <div className="id"> {t("ID_NO")}</div>
                    <div className="act"> {t("ORDER_PLACER")}</div>
                    <div className="name"> {t("FARM_NAME")}</div>
                    <div className="orderTime"> {t("BOOKING_DATE")}</div>
                    <div className="setTime"> {t("APPOINTMENT_DATE")}</div>
                    <div className="status">{t("STATUS")}</div>
                    <div className="request"> {t("REQUEST_CREATOR")}</div>
                    <div className="method"> {t("TEST_METHOD")}</div>
                    <div className="samples"> {t("TOTAL_SAMPLES")}</div>
                    <div className="total">{t("TOTAL")}</div>
                  </div>
                ) : (
                  <div className="list-header">
                    <div className="id">{t("ORDER_ID")}</div>
                    <div className="act"> {t("ORDER_PLACER")}</div>
                    <div className="setTime">{t("ORDER_TIME")}</div>
                    <div className="orderTime">{t("DELIVERY_DATE")}</div>
                    <div className="status">
                      {checkSourceFeed &&
                      (selectStatus === "all" || selectStatus === OrderStatus.COMPLETED) ? (
                        <Select
                          variant="standard"
                          disableUnderline
                          renderValue={() => <span> {t("STATUS")}</span>}
                          displayEmpty
                          inputProps={{ readOnly: false }}
                          MenuProps={{
                            className: "select-menu-list select-menu-status",
                            anchorOrigin: {
                              vertical: 20,
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                          }}>
                          {[
                            {
                              label: "Phản hồi",
                              value: "isFeedBack",
                            },
                          ].map((s, idx: number) => {
                            return (
                              <MenuItem
                                key={idx}
                                onClick={() => {
                                  setSelectSubStatus(s.value);
                                }}>
                                {t(s.label)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      ) : (
                        t("STATUS")
                      )}
                    </div>
                    <div className="total">{t("TOTAL")}</div>
                  </div>
                )}
                {state.data.length > 0 ? (
                  <InfiniteScroll
                    useWindow={false}
                    pageStart={0}
                    initialLoad={false}
                    hasMore={hasMore}
                    loadMore={() =>
                      fetchData({
                        limit: 10,
                        page: state.page + 1,
                        from: range.startTime.toISOString(),
                        to: range.endTime.toISOString(),
                        strSearch: searchValue,
                        source: sourceType,
                        status: selectStatus,
                        subStatus: selectSubStatus === "isFeedBack" ? "all" : undefined,
                        isFeedBack: selectSubStatus === "isFeedBack" ? true : undefined,
                      })
                    }>
                    {checkSourceLab
                      ? state.data.map((u: OrderOutputModel, index: number) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                if (checkPermissionOrderDetail) {
                                  if (sourceType === SourceType.LAB) {
                                    history.push({
                                      pathname: APP_ROUTES.ORDER_DETAIL_LAB.replace(
                                        ":orderId",
                                        u.id.toString()
                                      ),
                                      state: {
                                        startTime: range.startTime.toISOString(),
                                        endTime: range.endTime.toISOString(),
                                        status: selectStatus,
                                      },
                                    });
                                  } else {
                                    history.push({
                                      pathname: APP_ROUTES.ORDER_DETAIL_FARM.replace(
                                        ":orderId",
                                        u.id.toString()
                                      ),
                                      state: {
                                        startTime: range.startTime.toISOString(),
                                        endTime: range.endTime.toISOString(),
                                        status: selectStatus,
                                      },
                                    });
                                  }
                                } else {
                                  enqueueSnackbar(t("ACCESS_DENIED"), {
                                    preventDuplicate: true,
                                    variant: "error",
                                  });
                                }
                              }}
                              className={`item-lab ${u.status}`}>
                              <div className={`id id-${u.status}`}>{u?.code}</div>
                              <div className="act"> {u.createdBy?.name || TEXT_HOLDER}</div>
                              <div className="name">{u.orders[0]?.camp?.name || TEXT_HOLDER}</div>
                              <div className="orderTime">
                                {moment(u.createdAt).format(TIME.DAY_MONTH_YEAR)}
                              </div>
                              <div className="setTime">
                                {moment(u.orders[0]?.requiredDate).format(TIME.DAY_MONTH_YEAR)}
                              </div>
                              <div className="status">
                                {StatusUIUtil.renderCircleStatus(
                                  "all",
                                  u?.status,
                                  sourceType,
                                  undefined
                                )}
                              </div>

                              <div className="request">
                                {/* <div className="info__img">
                                  <img
                                    alt="employee avatar"
                                    src={
                                      checkLinkImage(
                                        u.orders[0].customer?.account?.avatar as any
                                      ) || renderImageProNull()
                                    }
                                    onError={renderErrorImageItem()}
                                  />
                                </div> */}
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                  <ThemeProvider theme={theme}>
                                    <Tooltip
                                      title={<span>{dataCustomer?.fullName || TEXT_HOLDER}</span>}>
                                      <span>{dataCustomer?.fullName || TEXT_HOLDER}</span>
                                    </Tooltip>
                                  </ThemeProvider>

                                  <span style={{ color: "#838283", paddingTop: "0.5rem" }}>
                                    {dataCustomer?.code || TEXT_HOLDER}
                                  </span>
                                </div>
                              </div>
                              <div className="method">
                                {u.orders[0]?.itemGroup?.name || TEXT_HOLDER}
                              </div>
                              <div className="samples">{u.totalSample || TEXT_HOLDER}</div>

                              <div className="total">
                                {checkPermissionViewPrice
                                  ? currencyFormat(parseInt(u.totalAmount))
                                  : TEXT_ASTERISK}{" "}
                                đ
                                {/* <span
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  marginTop: "1rem",
                                }}>
                                <WeightIcon
                                  color={"#272B2F"}
                                  size={DEFAULT_SIZE}
                                  viewBox={[22, 18]}
                                />
                                {currencyFormat(
                                  u?.orders.reduce((sum: any, item: any) => {
                                    const a = item.orderlines.reduce((sum: any, line: any) => {
                                      sum += +line.dimension;
                                      return sum;
                                    }, 0);
                                    return (sum += a);
                                  }, 0)
                                )}{" "}
                                kg
                              </span> */}
                              </div>
                            </div>
                          );
                        })
                      : state.data.map((u: Orders) => {
                          return (
                            <div
                              key={u.id}
                              className={`item ${u.status}`}
                              onClick={() => {
                                if (
                                  u.status === OrderStatus.DRAFT &&
                                  sourceType === SourceType.FEED
                                ) {
                                  if (checkCustomerDiv) {
                                    history.push({
                                      pathname: APP_ROUTES.UPDATE_ORDER_DRAFT.replace(
                                        ":orderId",
                                        u.id.toString()
                                      ),
                                      state: {
                                        startTime: range.startTime.toISOString(),
                                        endTime: range.endTime.toISOString(),
                                        status: selectStatus,
                                      },
                                    });
                                  } else {
                                    setNotiCheckCusDiv(true);
                                  }
                                } else if (
                                  u.status === OrderStatus.DRAFT &&
                                  sourceType === SourceType.VET
                                ) {
                                  history.push({
                                    pathname: APP_ROUTES.UPDATE_ORDER_DRAFT_VET.replace(
                                      ":orderId",
                                      u.id.toString()
                                    ),
                                    state: {
                                      startTime: range.startTime.toISOString(),
                                      endTime: range.endTime.toISOString(),
                                      status: selectStatus,
                                    },
                                  });
                                } else {
                                  if (
                                    u.status === OrderStatus.DRAFT &&
                                    sourceType === SourceType.FARM
                                  ) {
                                    history.push({
                                      pathname: APP_ROUTES.UPDATE_ORDER_DRAFT_FARM.replace(
                                        ":orderId",
                                        u.id.toString()
                                      ),
                                      state: {
                                        startTime: range.startTime.toISOString(),
                                        endTime: range.endTime.toISOString(),
                                        status: selectStatus,
                                      },
                                    });
                                  } else {
                                    if (checkPermissionOrderDetail) {
                                      history.push({
                                        pathname: (sourceType === SourceType.FARM
                                          ? APP_ROUTES.ORDER_DETAIL_FARM
                                          : sourceType === SourceType.VET
                                          ? APP_ROUTES.ORDER_DETAIL_VET
                                          : APP_ROUTES.ORDER_DETAIL
                                        ).replace(":orderId", u.id.toString()),
                                        state: {
                                          startTime: range.startTime.toISOString(),
                                          endTime: range.endTime.toISOString(),
                                          status: selectStatus,
                                        },
                                      });
                                    } else {
                                      enqueueSnackbar(t("ACCESS_DENIED"), {
                                        preventDuplicate: true,
                                        variant: "error",
                                      });
                                    }
                                  }
                                }
                              }}>
                              <div className={`id id-${u.status}`}>{u.code}</div>
                              <div className="act"> {u.createdBy?.name || TEXT_HOLDER}</div>
                              <div className="setTime">
                                {moment(u.createdAt).format(TIME.DAY_MONTH_YEAR)}
                              </div>
                              <div className="orderTime">
                                {moment(u.orders[0]?.requiredDate).format(TIME.DAY_MONTH_YEAR)}
                              </div>
                              <div
                                className="status"
                                style={{ display: "flex", alignItems: "flex-end" }}>
                                {StatusUIUtil.renderCircleStatus(selectStatus, u.status)}
                                {sourceType === SourceType.FEED &&
                                  selectStatus === OrderStatus.COMPLETED &&
                                  u.feedback && (
                                    <Chip
                                      label="Phản hồi"
                                      style={{ background: "#F6CD00" }}
                                      sx={{
                                        height: 16,
                                        marginLeft: 1,
                                        "& .MuiChip-label": {
                                          fontSize: 11,
                                          color: "#fff",
                                          fontWeight: 700,
                                          fontFamily: "SVN-Gotham",
                                          marginBottom: 0.4,
                                        },
                                      }}
                                    />
                                  )}
                              </div>
                              <div className="total">
                                {checkPermissionViewPrice
                                  ? currencyFormat(parseInt(u.totalAmount))
                                  : TEXT_ASTERISK}{" "}
                                đ
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    marginTop: "1rem",
                                  }}>
                                  <WeightIcon
                                    color={"#272B2F"}
                                    size={DEFAULT_SIZE}
                                    viewBox={[22, 18]}
                                  />
                                  {currencyFormat(
                                    u?.orders.reduce((sum: any, item: any) => {
                                      const a = item.orderlines.reduce((sum: any, line: any) => {
                                        sum += +line.dimension;
                                        return sum;
                                      }, 0);
                                      return (sum += a);
                                    }, 0)
                                  )}{" "}
                                  kg
                                </span>
                              </div>
                            </div>
                          );
                        })}
                  </InfiniteScroll>
                ) : (
                  <>
                    <div className="list-null">
                      {visibleSearch === true ? (
                        <>
                          <img src={Search} alt="search" />
                          <br />
                          {t("NO_RESULT_FOUND")}
                        </>
                      ) : (
                        <>
                          <EmptyList
                            img={Ord}
                            title={t("NO_ORDER_PLACED")}
                            text={t("PLACE_ORDER_NOW")}
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <EmptyList img={Ord} title={t("NO_ORDER_PLACED")} text={t("PLACE_ORDER_NOW")} />
            )}
          </>
        )}
      </div>

      <FloatingBtn
        visible={false}
        onClickPrimary={() => {
          if (!checkPermissionOrderCreate) {
            enqueueSnackbar(t("ACCESS_DENIED"), {
              preventDuplicate: true,
              variant: "error",
            });
            return;
          } else {
            if (!checkCustomerDiv) {
              setNotiCheckCusDiv(true);
            } else {
              history.push("create-" + sourceType);
            }
          }
        }}
        btns={[]}
      />

      <Dialog maxWidth="lg" open={comingsoon} className="dialog-coming">
        <ClearIcon
          style={{
            fontSize: "3rem",
            cursor: "pointer",
            margin: "2rem 2rem",
            position: "absolute",
            top: "1.6rem",
            right: "1.6rem",
          }}
          onClick={() => {
            setComingsoon(false);
          }}
        />
        <ComingSoon
          closeForm={() => {
            setComingsoon(false);
          }}
        />
      </Dialog>

      <Notification
        visible={notiActivated.status}
        title={
          notiActivated.message === "SESSION_EXPIRED"
            ? t("YOUR_LOGIN_SESSION_EXPIRED")
            : t("YOUR_ACCOUNT_DISABLED")
        }
        subTitle={
          notiActivated.message === "SESSION_EXPIRED" ? (
            <span>{t("LOGIN_AGAIN")}</span>
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: t("CONTACT_STAFF_GF_ACTIVE_ACCOUNT"),
              }}
            />
          )
        }
        icon={
          <div className={`notification-icon-box red`}>
            <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <button
            className="btn-confirm-style"
            onClick={() => {
              localStorage.removeItem("accessToken");
              window.location.reload();
              history.push(APP_ROUTES.SIGNIN);
            }}>
            {notiActivated.message === "SESSION_EXPIRED" ? t("CLOSE") : t("GO_BACK")}
          </button>
        }
        onClose={() => {
          localStorage.removeItem("accessToken");
          window.location.reload();
          history.push(APP_ROUTES.SIGNIN);
        }}
      />

      <Notification
        visible={notiCheckCusDiv}
        title={t("NOTIFICATION")}
        subTitle={
          <span
            dangerouslySetInnerHTML={{
              __html: t("CUSTOMER_LOCKED_BUSINESS"),
            }}
          />
        }
        icon={
          <div className={`notification-icon-box yellow`}>
            <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => {
                setNotiCheckCusDiv(false);
              }}>
              {t("CLOSE")}
            </button>
          </>
        }
        onClose={() => setNotiCheckCusDiv(false)}
      />
    </div>
  );
};

export default OrderListing;
