import DownApp from "@Components/DownApp";
import Loading from "@Components/Loading";
import { APP_ROUTES } from "@Constants";
import NotFound from "@Containers/NotFound";
import { UnAuthRoute } from "@Containers/SignInForm/UnAuthRoutes";
import { appRoutesAuth } from "@Containers/SignInForm/routes";
import Layout from "@Core/Layout";
import { appRoutes } from "@Core/Layout/routes";
import PrivateRoute from "@Core/auth/PrivateRoute";
import PrivateRouteWithOTP from "@Core/auth/PrivateRouteWithOTP";
import { useBreakpoints } from "@Hooks/useBreakpoints";
import React from "react";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
//rebuild
function App() {
  const breakpoints = useBreakpoints();
  return (
    <div className="App">
      {breakpoints.md && (
        <React.Suspense fallback={<Loading />}>
          <Router>
            <Switch>
              {UnAuthRoute.map((route, index) => {
                return <Route key={index} {...route} />;
              })}
              <PrivateRoute>
                <Switch>
                  {appRoutesAuth.map((route, index) => {
                    return <Route key={index} {...route} />;
                  })}
                  <PrivateRouteWithOTP>
                    <Route
                      exact
                      path={APP_ROUTES.SIGNIN}
                      render={() => <Redirect to={APP_ROUTES.HOME} />}
                    />
                    <Layout>
                      <Switch>
                        {appRoutes.map((route, index) => {
                          return <Route key={index} {...route} exact />;
                        })}
                        <Route component={NotFound} />
                      </Switch>
                    </Layout>
                  </PrivateRouteWithOTP>
                </Switch>
              </PrivateRoute>
            </Switch>
          </Router>
        </React.Suspense>
      )}
      {breakpoints.xs && !!breakpoints.md !== true ? <DownApp /> : ""}
    </div>
  );
}

export default App;
