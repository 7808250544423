import IconPolicy from "@Assets/images/icons/ic-polici.png";
import { ChangePasswordIcon } from "@Components/Icons/ChangePassword";
import { IdIcon } from "@Components/Icons/IdIcon";
import { ProfileIcon } from "@Components/Icons/ProfileIcon";
import { SignatureIcon } from "@Components/Icons/Signature";
import { APP_ROUTES } from "@Constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import "./LeftNavigation.scss";

export const DEFAULT_COLOR = "#0A6836";
export const ACTIVE_COLOR = "#FFF";
export const DEFAULT_SIZE: [number, number] = [30, 30];

const MenuProfile = [
  {
    icon: <ProfileIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    label: "Hồ sơ",
    url: APP_ROUTES.SETTING,
  },
  {
    icon: <IdIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[30, 20]} />,
    label: "CMND / CCCD",
    url: APP_ROUTES.IDENTITY,
  },
  {
    icon: <ChangePasswordIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[30, 25]} />,
    label: "Đổi mật khẩu",
    url: APP_ROUTES.RESET_PASSWORD,
  },
  {
    icon: <SignatureIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[30, 20]} />,
    label: "Chữ ký",
    url: APP_ROUTES.SIGNATURE,
  },
  {
    icon: <img src={IconPolicy} alt="ic-policy" style={{ width: 30, height: 30 }} />,
    label: "PRIVATE_POLICY",
    url: APP_ROUTES.PRIVACY_POLICY,
  },
];

const ProfileRoute: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  const location = useLocation();
  const isActiveRoute = (pathname: string): boolean => {
    return location.pathname === pathname;
  };
  return (
    <div className="left-nav">
      {MenuProfile.map((m) => {
        return (
          <div
            className={isActiveRoute(m.url) ? "item item-active" : "item"}
            key={m.label}
            onClick={() => {
              history.push(m.url);
            }}>
            {m.icon}&emsp;{t(m.label)}
          </div>
        );
      })}
    </div>
  );
};

export default ProfileRoute;
