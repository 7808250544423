/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-useless-fragment */
import Excel from "@Assets/images/icons/excel_w.png";
import ProNull from "@Assets/images/nopro.png";
import SampleEmpty from "@Assets/images/ordnull.png";
import DOList from "@Components/DO";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import {
  APP_ROUTES,
  BASE_LINK_IMAGE_V2,
  OrderStatusLab,
  ReasonStatus,
  TEXT_ASTERISK,
  TEXT_HOLDER,
  TIME,
  UpdateHistory,
} from "@Constants";
import {
  ItemPermission,
  OrderDetailLabOutputModel,
  OrderElementModel,
  OrderLabOutcomeImage,
  OrderLabOutComeResult,
  OrderLabOutComeSample,
  OrderLine,
  OrderPermission,
  OrderStatus,
  Reason,
  SourceType,
} from "@Models";
import { CustomerService, OrderService } from "@Services";
import { currencyFormat, PermissionUtil, StatusUIUtil } from "@Utils";
import {
  ArrowDropDown as ArrowDropDownIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Clear as ClearIcon,
  ExpandMore as ExpandMoreIcon,
  InfoOutlined as InfoIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
} from "@mui/icons-material";
import GridViewIcon from "@mui/icons-material/GridView";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import _debounce from "lodash/debounce";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import Bill from "./Bill";
import "./OrderDetailLab.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export type DuplicateModel = {
  sampleCode: string;
  item: {
    itemGroup: {
      id: number;
      code: string;
      name: string;
      source: SourceType;
      image: string | null;
      createdAt: string;
      updatedAt: string;
    };
    items: any[];
  }[];
};

SwiperCore.use([Autoplay, Pagination]);

const OrderDetailLab: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  const mounted = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const { orderId }: { orderId: string } = useParams();
  const [order, setOrder] = useState<OrderDetailLabOutputModel | null>(null);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [cancelValue, setCancelValue] = useState<string>(Reason.NOT_RESPONSE);
  const [reason, setReason] = useState<string>("");
  const [submitCancel, setSubmitCancel] = useState<boolean>(false);
  const [openListDO, setOpenListDO] = useState<number>(0);
  const location = useLocation<any>();
  const [notiCheckCusDiv, setNotiCheckCusDiv] = useState<boolean>(false);
  const [openBill, setOpenBill] = useState<boolean>(false);
  const [visibleDuplicate, setVisibleDuplicate] = useState<boolean>(false);
  const [visibleDetail, setVisibleDetail] = useState<number | null>(null);
  const [showConfirm, setShowConfirm] = useState<string | null>(null);
  const [priceList, setPriceList] = useState<OrderLine[]>([]);
  const [visibleResultSample, setVisibleResultSample] = useState<OrderLabOutcomeImage[]>([]);
  const [visibleSampleDetail, setVisibleSampleDetail] = useState<OrderLabOutcomeImage[]>([]);
  const [activeOutcome, setActiveOutcome] = useState<{
    target: boolean;
    data: OrderLabOutcomeImage | null;
  }>({ target: false, data: null });
  const [numPages, setNumPages] = useState<number | null>(null);

  const submitRef = useRef<any | null>(null);

  const [profile, setProfile] = useState<any>([]);
  const loadProfileData = _debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    setProfile(response.data);
  }, 100);

  const orderById: () => Promise<void> = async () => {
    try {
      const response = await OrderService.getOrderById(orderId);
      if (mounted.current) {
        setOrder(() => response.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    orderById();
    loadProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stepIcon = (props: StepIconProps, idx: number) => {
    const { active, completed } = props;
    return (
      <div
        className={`step-icon ${active ? "active" : completed ? "completed" : "default"}`}
        key={idx}>
        <div>{idx + 1}</div>
      </div>
    );
  };

  const checkStatus =
    order &&
    (order.status === OrderStatusLab.DRAFT ||
      order.status === OrderStatusLab.CANCELLED ||
      order.status === OrderStatusLab.CREATED ||
      order.status === OrderStatusLab.REJECTED);

  const checkOption =
    order &&
    (order.status === OrderStatusLab.CANCELLED ||
      order.status === OrderStatusLab.COMPLETED ||
      order.status === OrderStatusLab.REJECTED ||
      order.status === OrderStatusLab.RECEIVED ||
      order.status === OrderStatusLab.SCHEDULED);

  const checkViewSample =
    (order && order.status === OrderStatusLab.PROCESSING) ||
    order?.status === OrderStatusLab.CONFIRMED;

  const checkOutcomeSample =
    order &&
    (order.status === OrderStatusLab.COMPLETED ||
      order.status === OrderStatusLab.SCHEDULED ||
      order.status === OrderStatusLab.RECEIVED);

  const status = Array.from(
    new Set(
      order?.orders.map((o: any) => {
        return o.status;
      })
    )
  );

  const statusActive = Object.keys(OrderStatusLab)
    .filter(
      (i) =>
        OrderStatusLab[i as keyof typeof OrderStatusLab] !== OrderStatusLab.DRAFT &&
        OrderStatusLab[i as keyof typeof OrderStatusLab] !== OrderStatusLab.CANCELLED
    )
    .findIndex((i) => OrderStatusLab[i as keyof typeof OrderStatusLab] === order?.status);

  const cancelOrderlab = async () => {
    if (submitRef.current) {
      submitRef.current.disabled = true;
    }
    try {
      await OrderService.cancelOrderLab({
        reasonStatus: cancelValue,
        orderGroupId: +orderId,
        reason: reason,
        source: SourceType.LAB,
      });
      enqueueSnackbar(t("ORDER_CANCELLED_SUCCESSFULLY"), {
        variant: "success",
      });
      history.push(APP_ROUTES.ORDER_LAB);
    } catch (error) {
      enqueueSnackbar(t("CANCEL_ORDER_FAILED"), {
        variant: "error",
      });
      setSubmitCancel(false);
      submitRef.current.disabled = false;
    }
    setCancelValue(Reason.NOT_RESPONSE);
    setReason("");
  };

  const handleChangeStatusLab = async () => {
    if (submitRef.current) {
      submitRef.current.disabled = true;
    }
    if (showConfirm) {
      try {
        await OrderService.updateOrderLabStatus(orderId, { status: showConfirm });
        enqueueSnackbar(t("UPDATE_STATUS_LAB_SUCCESSFUL"), {
          variant: "success",
        });
        window.location.reload();
      } catch (error: any) {
        enqueueSnackbar(t("UPDATE_STATUS_LAB_FAILED"), {
          variant: "error",
        });
        setShowConfirm(null);
        submitRef.current.disabled = false;
      }
    }
  };

  const handleDataDuplicate = (obj: any) => {
    return Object.entries(obj).map(([key, value]) => ({
      sampleCode: key,
      item: value as any[],
    }));
  };

  const dataDuplicate: DuplicateModel[] = !!order?.duplicateSample
    ? handleDataDuplicate(order?.duplicateSample)
    : [];

  const renderTitle = (status: string) => {
    switch (status) {
      case OrderStatusLab.SCHEDULED:
        return t("SAMPLE_RESULT_RECEPTION");
      case OrderStatusLab.COMPLETED:
        return t("RESULT_BIOLAB");
      case OrderStatusLab.RECEIVED:
        return t("DETAIL_RECEIVED");
      default:
        return t("DETAIL_BIOLAB");
    }
  };

  //permission
  const checkPermissionCancelOrder = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_CANCEL,
    true
  );

  const checkPermissionUpdateOrder = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_UPDATE,
    true
  );

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );
  const checkPermissionUpdateSample = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_UPDATE_SAMPLE,
    true
  );

  const sampleInfo: OrderElementModel | null = order?.orders.find(
    (i) => i.id === visibleDetail || i.id === visibleDetail
  )!;

  return (
    <Dialog fullScreen open={true}>
      <div className="order-detail">
        <SimpleModalHeader
          title={order && renderTitle(order?.status)}
          onClose={() => {
            history.push({
              pathname: APP_ROUTES.ORDER_LAB,
              state: {
                startTime: location.state?.startTime,
                endTime: location.state?.endTime,
                status: location.state?.status,
              },
            });
          }}
        />
        <div className="detail-container">
          {!!order ? (
            <>
              <div className="content">
                <Grid container className="header">
                  <Grid item xs={3} className="left">
                    <div className="label">{t("FARM_NAME")}</div>
                    <span className="name">{order.orders[0]?.camp?.name || TEXT_HOLDER}</span>
                  </Grid>

                  {!checkStatus && (
                    <Grid item xs={7} className="col">
                      <Stepper className="content-step" alternativeLabel activeStep={statusActive}>
                        {Object.keys(OrderStatusLab)
                          .filter(
                            (i) =>
                              OrderStatusLab[i as keyof typeof OrderStatusLab] !==
                                OrderStatusLab.DRAFT &&
                              OrderStatusLab[i as keyof typeof OrderStatusLab] !==
                                OrderStatusLab.CANCELLED
                          )
                          .map((label, idx) => {
                            return (
                              <Step key={idx}>
                                <StepLabel
                                  className="content-step_label"
                                  StepIconComponent={(e) => stepIcon(e, idx)}>
                                  {t(
                                    `LAB_${OrderStatusLab[
                                      label as keyof typeof OrderStatusLab
                                    ].toUpperCase()}`
                                  )}
                                </StepLabel>
                              </Step>
                            );
                          })}
                      </Stepper>
                    </Grid>
                  )}

                  {!checkOption && (
                    <Grid item xs={2} justifyContent="flex-end" display="flex">
                      {status.length !== 1 ? (
                        <Select
                          variant="outlined"
                          className="btn-green-style btn--shiny"
                          renderValue={() => <span>{t("OPTIONS")}</span>}
                          displayEmpty
                          startAdornment={
                            <InputAdornment position="start">
                              <GridViewIcon />
                            </InputAdornment>
                          }
                          inputProps={{ readOnly: false }}
                          MenuProps={{
                            className: "select-menu-list",
                            anchorOrigin: {
                              vertical: 60,
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                          }}>
                          {order?.orders.map((o) => {
                            return (() => {
                              switch (o.status) {
                                case OrderStatusLab.DRAFT || OrderStatusLab.CREATED:
                                  return (
                                    <>
                                      {o.canCancelOrUpdate === true && (
                                        <>
                                          <MenuItem
                                            onClick={() => {
                                              checkPermissionCancelOrder
                                                ? setShowCancel(true)
                                                : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                    preventDuplicate: true,
                                                    variant: "error",
                                                  });
                                            }}>
                                            {t("CANCEL_ORDER")}
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              checkPermissionUpdateOrder
                                                ? history.push(
                                                    APP_ROUTES.UPDATE_ORDER_LAB.replace(
                                                      ":orderId",
                                                      order.id.toString()
                                                    )
                                                  )
                                                : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                    preventDuplicate: true,
                                                    variant: "error",
                                                  });
                                            }}>
                                            {t("EDIT_ORDER_LAB")}
                                          </MenuItem>
                                        </>
                                      )}
                                    </>
                                  );
                                case OrderStatus.PROCESSING:
                                  return (
                                    <>
                                      <MenuItem
                                        onClick={() => {
                                          history.push(
                                            APP_ROUTES.CREATE_FEED_ID.replace(
                                              ":orderId",
                                              order.id.toString()
                                            )
                                          );
                                        }}>
                                        {t("RESET_ORDER")}
                                      </MenuItem>
                                    </>
                                  );
                                case OrderStatus.COMPLETED:
                                  return (
                                    <>
                                      <MenuItem
                                        onClick={() => {
                                          history.push(
                                            APP_ROUTES.CREATE_FEED_ID.replace(
                                              ":orderId",
                                              order.id.toString()
                                            )
                                          );
                                        }}>
                                        {t("RESET_ORDER")}
                                      </MenuItem>
                                    </>
                                  );
                                case OrderStatus.CREATED:
                                  return (
                                    <>
                                      {o.canCancelOrUpdate === true && (
                                        <MenuItem
                                          onClick={() => {
                                            checkPermissionUpdateOrder
                                              ? history.push(
                                                  APP_ROUTES.UPDATE_ORDER_LAB.replace(
                                                    ":orderId",
                                                    order.id.toString()
                                                  )
                                                )
                                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                  preventDuplicate: true,
                                                  variant: "error",
                                                });
                                          }}>
                                          {t("EDIT_ORDER_LAB")}
                                        </MenuItem>
                                      )}
                                    </>
                                  );
                                case OrderStatus.CONFIRMED:
                                  return (
                                    <>
                                      {o.canCancelOrUpdate === true && (
                                        <MenuItem
                                          onClick={() => {
                                            checkPermissionCancelOrder
                                              ? setShowCancel(true)
                                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                  preventDuplicate: true,
                                                  variant: "error",
                                                });
                                          }}>
                                          {t("CANCEL_ORDER")}
                                        </MenuItem>
                                      )}
                                    </>
                                  );
                                default:
                                  return;
                              }
                            })();
                          })}
                        </Select>
                      ) : (
                        // eslint-disable-next-line array-callback-return
                        // checkOption && (
                        <Select
                          variant="outlined"
                          className="btn-green-style btn--shiny"
                          renderValue={() => <span>{t("OPTIONS")}</span>}
                          displayEmpty
                          startAdornment={
                            <InputAdornment position="start">
                              <GridViewIcon />
                            </InputAdornment>
                          }
                          inputProps={{ readOnly: false }}
                          MenuProps={{
                            className: "select-menu-list",
                            anchorOrigin: {
                              vertical: 60,
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                          }}>
                          {(() => {
                            switch (order.status) {
                              case OrderStatusLab.DRAFT:
                                return (
                                  <>
                                    {order.orders[0]?.canCancelOrUpdate === true && (
                                      <>
                                        <MenuItem
                                          onClick={() => {
                                            checkPermissionUpdateOrder
                                              ? history.push(
                                                  APP_ROUTES.UPDATE_ORDER_LAB.replace(
                                                    ":orderId",
                                                    order.id.toString()
                                                  )
                                                )
                                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                  preventDuplicate: true,
                                                  variant: "error",
                                                });
                                          }}>
                                          {t("EDIT_ORDER_LAB")}
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() => {
                                            checkPermissionCancelOrder
                                              ? setShowCancel(true)
                                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                  preventDuplicate: true,
                                                  variant: "error",
                                                });
                                          }}>
                                          {t("CANCEL_REQUEST")}
                                        </MenuItem>
                                      </>
                                    )}
                                  </>
                                );
                              case OrderStatusLab.CREATED:
                                return (
                                  <>
                                    {order.orders[0]?.canCancelOrUpdate === true && (
                                      <MenuItem
                                        onClick={() => {
                                          checkPermissionUpdateOrder
                                            ? history.push(
                                                APP_ROUTES.UPDATE_ORDER_LAB.replace(
                                                  ":orderId",
                                                  order.id.toString()
                                                )
                                              )
                                            : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                preventDuplicate: true,
                                                variant: "error",
                                              });
                                        }}>
                                        {t("EDIT_ORDER_LAB")}
                                      </MenuItem>
                                    )}
                                    <MenuItem
                                      onClick={() => {
                                        checkPermissionCancelOrder
                                          ? setShowCancel(true)
                                          : enqueueSnackbar(t("ACCESS_DENIED"), {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      }}>
                                      {t("CANCEL_REQUEST")}
                                    </MenuItem>
                                  </>
                                );
                              case OrderStatusLab.CONFIRMED:
                                return (
                                  <>
                                    <MenuItem
                                      onClick={() => {
                                        checkPermissionUpdateSample
                                          ? history.push(
                                              APP_ROUTES.UPDATE_ORDER_LAB_SAMPLE.replace(
                                                ":orderId",
                                                order.id.toString()
                                              )
                                            )
                                          : enqueueSnackbar(t("ACCESS_DENIED"), {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      }}>
                                      {t("UPDATE_SAMPLE")}
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        checkPermissionCancelOrder
                                          ? setShowCancel(true)
                                          : enqueueSnackbar(t("ACCESS_DENIED"), {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      }}>
                                      {t("CANCEL_REQUEST")}
                                    </MenuItem>
                                  </>
                                );
                              case OrderStatusLab.PROCESSING:
                                return (
                                  <>
                                    <MenuItem
                                      onClick={() => {
                                        checkPermissionCancelOrder
                                          ? setShowCancel(true)
                                          : enqueueSnackbar(t("ACCESS_DENIED"), {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      }}>
                                      {t("CANCEL_REQUEST")}
                                    </MenuItem>
                                  </>
                                );
                              default:
                                return;
                            }
                          })()}
                        </Select>
                        // )
                      )}
                    </Grid>
                  )}
                </Grid>

                <Grid container className="info">
                  <Grid item xs={3}>
                    <div className="label">{t("ORDER_ID")}</div>#{order.code}
                    <br /> <br />
                    <div className="label">{t("TOTAL_SAMPLES")}</div>
                    {order.totalSample}
                    <br /> <br />
                    <div className="label">{t("NOTES")}</div>
                    {order.orders[0].note || TEXT_HOLDER}
                  </Grid>
                  <Grid item xs={3}>
                    <div className="label">{t("TARGET")}</div>
                    {order.target?.name || TEXT_HOLDER}
                    <br /> <br />
                    <div className="label">{t("APPOINTMENT_DATE")}</div>
                    {moment(order.orders[0]?.requiredDate).format(TIME.DAY_MONTH_YEAR)}
                  </Grid>
                  <Grid item xs={3}>
                    <div className="label">{t("OBJECTIVE")}</div>
                    {order.objective?.name || TEXT_HOLDER}
                    <br /> <br />
                    <div className="label">{t("CREATOR")}</div>
                    {order.createdBy?.account?.code && order.createdBy?.account?.code + " - "}
                    {order.createdBy?.fullName}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className="info-status"
                    style={{ paddingRight: "4rem", alignItems: "start" }}>
                    <div className="label">{t("REQUEST_TYPE")}</div>
                    {order.orders[0].requestType?.description || TEXT_HOLDER}
                    <br /> <br />
                    <div className="label">{t("STATUS")}</div>
                    {StatusUIUtil.renderBgStatus(order.status, SourceType.LAB)}
                  </Grid>
                </Grid>

                <div className="list">
                  {!checkOutcomeSample ? (
                    <div className="list-header">
                      <div className="stt">{t("STT")}</div>
                      <div className="method">{t("TEST_METHOD")}</div>
                      <div className="pathogen">{t("PATHOGEN")}</div>
                      <div className="quan">{t("TEST_QUANTITY")}</div>
                      <div className="type">{t("SAMPLE_TYPE")}</div>
                      <div className="total-quan">{t("TOTAL_TEST_QUANTITY")}</div>
                      <div className="total">{t("TOTAL_AMOUNT_ESTIMATED")}</div>
                      <div className="detail"></div>
                    </div>
                  ) : (
                    <div className="list-header">
                      <div className="index">{t("order:STT")}</div>
                      <div className="result">
                        {order.status === OrderStatusLab.SCHEDULED
                          ? t("SAMPLE_RESULT_BOXES")
                          : t("SAMPLE_RESULT_CONTENT")}
                      </div>
                      <div className="time">{t("SAMPLE_RESULT_TIME")}</div>
                      <div className="content">
                        {order.status === OrderStatusLab.SCHEDULED ? t("IMAGE") : t("VIEW_DETAIL")}
                      </div>
                    </div>
                  )}

                  {!checkOutcomeSample ? (
                    order.orders.map((g: OrderElementModel, idx: number) => {
                      const orderLine = [...g.orderlines];
                      return (
                        <div key={idx}>
                          <div className="item">
                            <div className="stt">
                              <div className="code">{idx + 1}</div>
                              {checkViewSample && (
                                <div
                                  className="code"
                                  onClick={() => {
                                    !!g.orderLabDetails.length && setVisibleDetail(g.id);
                                  }}
                                  style={{
                                    color: !!g.orderLabDetails.length ? "#0A6836" : "#A51D23",
                                    fontSize: "1.3rem",
                                    fontWeight: 400,
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}>
                                  {!!g.orderLabDetails.length
                                    ? t("VIEW_SAMPLE_INFO")
                                    : t("NOT_SAMPLE_INFO")}
                                </div>
                              )}
                            </div>
                            <div className="method">{g.itemGroup?.name}</div>
                            <div className="pathogen">
                              {orderLine.map((i) => i.item.name).join(", ")}
                            </div>

                            <div className="quan">{Number(g.quantity)}</div>
                            <div className="type">{g.sample.name}</div>
                            <div className="total-quan">
                              {orderLine.reduce((acc, curr) => acc + Number(curr.orderQuantity), 0)}
                            </div>
                            <div className="total">
                              {checkPermissionViewPrice
                                ? currencyFormat(Number(g.totalPay))
                                : TEXT_ASTERISK}
                              &ensp;đ
                            </div>
                            <div className="detail">
                              {false && (
                                <div onClick={() => setPriceList(orderLine)}>
                                  <InfoIcon style={{ fontSize: "2.5rem", color: "#272B2F" }} />
                                </div>
                              )}
                            </div>
                          </div>
                          <Divider />
                        </div>
                      );
                    })
                  ) : order.status === OrderStatusLab.COMPLETED ||
                    order.status === OrderStatusLab.RECEIVED ? (
                    <>
                      {/* result */}
                      {!!order.orderLabOutcome.length &&
                        order.orderLabOutcome?.map((g: OrderLabOutComeResult, idx: number) => {
                          return (
                            <div key={idx}>
                              <div className="item">
                                <div className="index">
                                  <div className="code">{idx + 1}</div>
                                </div>
                                <div className="result">{g.description || TEXT_HOLDER}</div>
                                <div className="time">
                                  {moment(g.responseDate).format(
                                    TIME.DAY_MONTH_YEAR_HOUR_MINUTE_SECOND
                                  ) || TEXT_HOLDER}
                                </div>
                                <div
                                  className="content"
                                  style={{
                                    color: "#41AD49",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setVisibleSampleDetail(g.orderLabOutcomeImages)}>
                                  {t("VIEW_DETAIL")}
                                </div>
                              </div>
                              <Divider />
                            </div>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {/* result samples */}
                      {!!order?.orderLabOutcomeSample?.length &&
                        order.orderLabOutcomeSample.map((g: OrderLabOutComeSample, idx: number) => {
                          return (
                            <div key={idx}>
                              <div className="item">
                                <div className="index">
                                  <div className="code">{idx + 1}</div>
                                </div>
                                <div className="result">{g.name || TEXT_HOLDER}</div>
                                <div className="time">
                                  {moment(g.responseDate).format(
                                    TIME.DAY_MONTH_YEAR_HOUR_MINUTE_SECOND
                                  ) || TEXT_HOLDER}
                                </div>
                                <div
                                  className="content"
                                  style={{
                                    color: "#41AD49",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setVisibleResultSample(g.orderLabOutcomeImages);
                                  }}>
                                  {t("VIEW_DETAIL")}
                                </div>
                              </div>
                              <Divider />
                            </div>
                          );
                        })}
                    </>
                  )}
                </div>

                {checkViewSample && (
                  <div
                    style={{
                      marginTop: "2rem",
                    }}>
                    <span
                      style={{
                        color: "#0A6836",
                        fontWeight: 500,
                        fontSize: "1.8rem",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setVisibleDuplicate(true)}>
                      {t("DUPLICATE_SAMPLE_CODE")}
                    </span>
                  </div>
                )}
              </div>
              <div className="footer">
                <div className="total-gr">
                  <div style={{ width: "40rem" }}>
                    {order.orders.some((f: any) => f.invoice !== null) &&
                      order.orders.some((f: any) => f.invoice?.invoiceKey !== "") && (
                        <>
                          <div className="title">{t("LOOKUP_CODE")}</div>
                          {order.orders
                            .filter((f: any) => f.invoice && f.invoice.invoiceKey !== "")
                            .map((i: any) => {
                              return (
                                <div style={{ display: "flex" }}>
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={i.invoice?.invoiceUrl}
                                    style={{ color: "#41AD49", cursor: "pointer" }}>
                                    #{i.invoice?.arNumber}
                                    <br />
                                    <br />
                                  </a>
                                  &ensp;
                                  <div>
                                    &#8226;&ensp;{t("LOOKUP_CODE")}&ensp;
                                    <span style={{ fontWeight: "bold" }}>
                                      {i.invoice?.invoiceKey}
                                    </span>
                                    <br />
                                    <br />
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      )}
                  </div>
                </div>
                <Accordion
                  // defaultExpanded={true}
                  disabled
                  className="update-history">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon style={{ color: "#272B2F" }} />}
                    className="title">
                    {t("UPDATE_HISTORY")}
                  </AccordionSummary>
                  <Divider style={{ marginBottom: "1.2rem" }} />
                  <AccordionDetails className="label">
                    <Grid container>
                      <Grid item xs={3}>
                        {t("TIME")}
                      </Grid>
                      <Grid item xs={3}>
                        {t("ACTION")}
                      </Grid>
                      <Grid item xs={2}>
                        {t("IMPLEMENT")}
                      </Grid>
                      <Grid item xs={4}>
                        {t("DETAIL")}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                  {/* <div className="list-update"> */}
                  {UpdateHistory.map((u) => {
                    return (
                      <AccordionDetails key={u.id} className="item">
                        <Grid container>
                          <Grid item xs={3}>
                            {u.time}
                          </Grid>
                          <Grid item xs={3}>
                            {u.update}
                          </Grid>
                          <Grid item xs={2}>
                            {u.name}
                          </Grid>
                          <Grid item xs={4}>
                            {u.update}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    );
                  })}
                  {/* </div> */}
                </Accordion>
              </div>
            </>
          ) : (
            <Loading style={{ margin: "2rem 0", height: "80vh" }} />
          )}
        </div>
      </div>

      <Dialog maxWidth="md" open={showCancel}>
        <div className="dialog-cancel">
          <div className="title">
            <div>{t("CANCEL_REQUEST_REASON")}</div>
            <ClearIcon
              style={{ fontSize: "3rem", cursor: "pointer" }}
              onClick={() => {
                setShowCancel(false);
                setCancelValue(Reason.NOT_RESPONSE);
                setReason("");
              }}
            />
          </div>
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={cancelValue}
            onChange={(e) => {
              setCancelValue(e.target.value);
            }}>
            {ReasonStatus.map((r) => {
              return (
                <FormControlLabel
                  key={r.id}
                  value={r.value}
                  control={<Radio color="success" />}
                  label={t(`LAB_${r.label}`)}
                  className="text"
                />
              );
            })}
            {cancelValue === Reason.OTHER && (
              <>
                <div className="label asterisk">{t("REASON")}</div>
                <TextField
                  fullWidth
                  placeholder={t("INPUT_BY_NAME", { NAME: t("REASON").toLowerCase() })}
                  className="form-textarea-field"
                  multiline
                  rows={3}
                  variant="outlined"
                  color="success"
                  inputProps={{ className: "input" }}
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                  error={!reason}
                />
              </>
            )}
          </RadioGroup>
          <div className="btn-group">
            <button
              className="btn-cancel btn__text-cancel"
              onClick={() => {
                setShowCancel(false);
                setCancelValue(Reason.NOT_RESPONSE);
                setReason("");
              }}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              style={{ marginLeft: "2.2rem" }}
              onClick={() => {
                if (!reason && cancelValue === Reason.OTHER) {
                } else {
                  setShowCancel(false);
                  setSubmitCancel(true);
                }
              }}>
              {t("CANCEL_REQUEST")}
            </button>
          </div>
        </div>
      </Dialog>

      <Notification
        visible={submitCancel}
        title={t("CONFIRM_CANCEL_ORDER_LAB")}
        subTitle={
          <span>
            {t("CANNOT_RECOVER_CANCELED_ORDER_LAB")}
            <br />
            {t("ARE_YOU_SURE_CANCEL_ORDER_LAB_BY_NAME", { NAME: `#${order?.code}` })}
          </span>
        }
        icon={
          <div className={`notification-icon-box red`}>
            <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                setSubmitCancel(false);
                setCancelValue(Reason.NOT_RESPONSE);
                setReason("");
              }}>
              {t("NO")}
            </button>
            <button
              ref={submitRef}
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => {
                cancelOrderlab();
              }}>
              {t("CONFIRM")}
            </button>
          </>
        }
        onClose={() => {
          setSubmitCancel(false);
        }}
      />

      <Notification
        visible={!!showConfirm}
        title={showConfirm === OrderStatusLab.CANCELLED ? t("LAB_CANCELLED") : t("CONFIRM_REQUEST")}
        subTitle={
          showConfirm === OrderStatusLab.CANCELLED ? (
            <span>{t("ARE_YOU_SURE_REJECT_ORDER_BY_NAME", { NAME: `#${order?.code}` })}</span>
          ) : (
            <span>{t("ARE_YOU_SURE_CONFIRM_ORDER_BY_NAME", { NAME: `#${order?.code}` })}</span>
          )
        }
        icon={
          <div className={`notification-icon-box red`}>
            <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => {
                setShowConfirm(null);
              }}>
              {t("CANCEL")}
            </button>
            <button
              ref={submitRef}
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => {
                handleChangeStatusLab();
              }}>
              {t("CONFIRM")}
            </button>
          </>
        }
        onClose={() => {
          setShowConfirm(null);
        }}
      />
      {openListDO !== 0 && <DOList closeForm={() => setOpenListDO(0)} id={openListDO} />}

      <Notification
        visible={notiCheckCusDiv}
        title={t("NOTIFICATION")}
        subTitle={
          <span
            dangerouslySetInnerHTML={{
              __html: t("CONTACT_STAFF_GF_ACTIVE_ACCOUNT"),
            }}
          />
        }
        icon={
          <div className={`notification-icon-box yellow`}>
            <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => {
                setNotiCheckCusDiv(false);
              }}>
              {t("CLOSE")}
            </button>
          </>
        }
        onClose={() => setNotiCheckCusDiv(false)}
      />

      <Dialog
        maxWidth="lg"
        open={openBill}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            // width: "427px",
            boxShadow: "none",
            borderRadius: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        onClose={() => {
          setOpenBill(false);
        }}>
        <Bill billData={order} />
      </Dialog>

      {/* chi tiết mẫu */}
      <Dialog fullWidth maxWidth="md" open={!!visibleDetail}>
        <ClearIcon
          style={{
            fontSize: "3rem",
            cursor: "pointer",
            margin: "2rem 2rem",
            position: "absolute",
            top: "1.6rem",
            right: "1.6rem",
          }}
          onClick={() => {
            setVisibleDetail(null);
          }}
        />
        <div className="dialog-detail">
          <div className="header">
            <div className="title">{t("DETAIL")}</div>
          </div>
          <div className="info">
            <div
              style={{
                fontSize: "1.6rem",
                fontWeight: 500,
              }}>
              {t("TEST_METHOD")} : {sampleInfo?.itemGroup?.name || TEXT_HOLDER}
            </div>
            <div
              style={{
                fontSize: "1.6rem",
                fontWeight: 500,
                padding: "1rem 0 2rem",
                borderBottom: "1px solid #f5f2f2",
              }}>
              {t("PATHOGEN")} :{" "}
              {sampleInfo?.orderlines?.map((i) => i.item.name).join(", ") || TEXT_HOLDER}
            </div>
          </div>
          <div className="content" style={{ height: "58rem" }}>
            <div className="content-header">
              <div className="id">{t("Stt")}</div>
              <div className="name">{t("OBJECTIVE_DETAIL")}</div>
              <div className="age">{t("AGE")}</div>
              <div className="code">{t("SAMPLE_CODE")}</div>
              {sampleInfo?.itemGroup?.code === "PCRG" && (
                <div className="age">{t("TEMPLATE_SAMPLE_GROUP")}</div>
              )}
            </div>
            <div className="body">
              {order?.orders
                .find((i) => i.id === visibleDetail)
                ?.orderLabDetails.map((item, idx) => {
                  return (
                    <div className="content-item" key={idx}>
                      <div className="id">{idx + 1}</div>
                      <div className="name">{item.objectiveDetail?.name || TEXT_HOLDER}</div>
                      <div className="age">{item.age}</div>
                      <div className="code">{item.sampleCode}</div>
                      {sampleInfo?.itemGroup?.code === "PCRG" && (
                        <div className="age">{item.mergeSample || TEXT_HOLDER}</div>
                      )}
                    </div>
                  );
                })}{" "}
            </div>
          </div>
        </div>
      </Dialog>

      {/* modal mẫu trùng */}
      <Dialog fullWidth maxWidth="md" open={!!visibleDuplicate}>
        <ClearIcon
          style={{
            fontSize: "4rem",
            cursor: "pointer",
            margin: "2rem 2rem",
            position: "absolute",
            top: "1.6rem",
            right: "1.6rem",
            backgroundColor: "#EFEFEF",
            padding: "5px",
            borderRadius: "5rem",
          }}
          onClick={() => {
            setVisibleDuplicate(false);
          }}
        />
        <div className="dialog-detail">
          <div className="header">
            <div className="title">{t("DUPLICATE_SAMPLE_CODE_INFO")}</div>
          </div>
          <div
            className="content"
            style={{
              height: !!dataDuplicate.length ? "55rem" : "auto",
            }}>
            <div className="content-header" style={{ backgroundColor: "#ffffff" }}>
              <div className="code">{t("SAMPLE_CODE")}</div>
              <div className="method">{t("TEST_METHOD")}</div>
            </div>
            <div className="body">
              {!!dataDuplicate.length ? (
                dataDuplicate?.map((val, idx) => {
                  return (
                    <Accordion className="list-child" key={idx}>
                      <AccordionSummary
                        className="title"
                        expandIcon={
                          <ExpandMoreIcon
                            style={{
                              color: "#0A6836",
                              fontSize: "2.2rem",
                            }}
                          />
                        }>
                        <div className="content-item-duplicate">
                          <div className="code">{val.sampleCode || TEXT_HOLDER}</div>
                          <div className="method">
                            {val.item?.length} {t("Phương pháp")}
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="content-item-duplicate-detail">
                          <div className="code"></div>
                          <div className="method">
                            {!!val.item?.length &&
                              val.item?.map((i, index: number) => {
                                return (
                                  <div className="method-item" key={index}>
                                    <div className="method-group">{i.itemGroup?.name}</div>
                                    <div className="method-pathogen">
                                      {!!i.items?.length &&
                                        i.items?.map((e: any, eIndex: number) => {
                                          return <div key={eIndex}>{e}</div>;
                                        })}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              ) : (
                <div style={{ textAlign: "center" }}>
                  <img src={SampleEmpty} alt="new-order" />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "1.6rem",
                      fontWeight: 500,
                    }}>
                    {t("Chưa có thông tin mẫu trùng")}
                  </div>
                </div>
              )}
            </div>
            <div className="footer_sample">
              <div className="footer_sample_content">
                {t("TOTAL_SAMPLES")}: {dataDuplicate.length}
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* chi tiết giá */}
      <Dialog fullWidth maxWidth="md" open={!!priceList.length}>
        <ClearIcon
          style={{
            fontSize: "3rem",
            cursor: "pointer",
            margin: "2rem 2rem",
            position: "absolute",
            top: "1.6rem",
            right: "1.6rem",
          }}
          onClick={() => {
            setPriceList([]);
          }}
        />
        <div className="dialog-price-list">
          <div className="header">
            <div className="title">{t("TOTAL_AMOUNT_DETAIL")}</div>
          </div>
          <div className="content">
            <div className="content-header">
              <div className="name">{t("PATHOGEN")}</div>
              <div className="unit">{t("UNIT_PRICE")}</div>
              <div className="quantity">{t("QUANTITY")}</div>
              <div className="price">{t("TOTAL_AMOUNT_ESTIMATED")}</div>
            </div>
            <div className="body">
              {!!priceList.length &&
                priceList?.map((item, idx) => {
                  return (
                    <div className="content-item" key={idx}>
                      <div className="name">{item.item?.name || TEXT_HOLDER}</div>
                      <div className="unit">
                        {checkPermissionViewPrice
                          ? currencyFormat(Number(item.price))
                          : TEXT_ASTERISK}
                        &ensp;đ
                      </div>
                      <div className="quantity">{parseFloat(item.orderQuantity)}</div>
                      <div className="price">
                        {checkPermissionViewPrice
                          ? currencyFormat(Number(item.subTotal))
                          : TEXT_ASTERISK}
                        &ensp;đ
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Dialog>

      {/* chi tiết kết quả nhân mẫu */}
      <Dialog fullWidth maxWidth="lg" open={!!visibleSampleDetail.length}>
        <ClearIcon
          style={{
            fontSize: "3rem",
            cursor: "pointer",
            margin: "2rem 2rem",
            position: "absolute",
            top: "1.6rem",
            right: "1.6rem",
          }}
          onClick={() => {
            setVisibleSampleDetail([]);
            setActiveOutcome({ target: false, data: null });
          }}
        />
        <div className="dialog-sample-detail-result">
          <div className="header">
            <div className="title">{t("SAMPLE_DETAIL")}</div>
          </div>
          <div className="content">
            <div className="content-left">
              <div className="content-left_header">
                <div className="name">No.</div>
                <div className="unit">
                  {t("SELECT_BY_NAME", {
                    NAME: t("ALL").toLowerCase(),
                  })}
                </div>
              </div>
              <div className="content-left_body">
                {visibleSampleDetail.map((i, idx) => {
                  return (
                    <div className="item" key={idx}>
                      <div className="name">{i.code || TEXT_HOLDER}</div>
                      <div className="unit">
                        <Checkbox
                          checked={
                            !!activeOutcome &&
                            i.id === activeOutcome?.data?.id &&
                            activeOutcome.target
                          }
                          checkedIcon={
                            <CheckBoxIcon style={{ fontSize: "2.2rem", color: "#41ad49" }} />
                          }
                          icon={
                            <CheckBoxOutlineBlankIcon
                              style={{
                                fontSize: "2.2rem",
                              }}
                            />
                          }
                          onChange={(e) => {
                            const result = {
                              target: e.target.checked,
                              data: e.target.checked ? i : null,
                            };
                            setActiveOutcome(result);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="content-right">
              <div className="content-right_header">
                <div className="title">{activeOutcome?.data?.code || TEXT_HOLDER}</div>
                <div className="action">
                  <button
                    className="btn-excel"
                    onClick={() => {}}
                    type="button"
                    style={{
                      background: "rgba(10, 104, 54, 1)",
                      color: "#fff",
                      marginRight: "2rem",
                    }}>
                    <img src={Excel} alt="icon-import-excel" />
                    {!!activeOutcome?.data ? (
                      <a
                        href={BASE_LINK_IMAGE_V2 + activeOutcome?.data?.data}
                        target="_blank"
                        rel="noopener noreferrer">
                        {t("EXPORT_DATA")}
                      </a>
                    ) : (
                      t("EXPORT_DATA")
                    )}
                  </button>
                  <ClearIcon
                    style={{ fontSize: "3.5rem", cursor: "pointer" }}
                    onClick={() => {
                      const result = {
                        target: false,
                        data: null,
                      };
                      setActiveOutcome(result);
                    }}
                  />
                </div>
              </div>
              <div className="content-right_body">
                {!!activeOutcome.data ? (
                  <Document
                    file={BASE_LINK_IMAGE_V2 + activeOutcome?.data?.data}
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                    {Array.from(Array(numPages || 0)).map((_, index) => (
                      <Page
                        key={index}
                        pageNumber={index + 1}
                        width={Math.min(595, window.innerWidth * 0.9)}
                      />
                    ))}
                  </Document>
                ) : (
                  <img src={ProNull} alt="search" width={190} height={178} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* modal hình ảnh mẫu nhận */}
      <Dialog fullWidth maxWidth="md" open={!!visibleResultSample.length}>
        <ClearIcon
          style={{
            fontSize: "4rem",
            cursor: "pointer",
            margin: "2rem 2rem",
            position: "absolute",
            top: "1.6rem",
            right: "1.6rem",
            backgroundColor: "#EFEFEF",
            padding: "5px",
            borderRadius: "5rem",
          }}
          onClick={() => {
            setVisibleResultSample([]);
          }}
        />
        <div className="dialog-result-sample">
          <div className="header">
            <div className="title">{t("DETAIL_RESULT_SAMPLE")}</div>
          </div>
          <div className="content">
            <Swiper
              modules={[Pagination]}
              spaceBetween={300}
              slidesPerView={1}
              pagination={{ clickable: true }}
              loop
              autoplay={{
                delay: 1000000,
                disableOnInteraction: false,
              }}>
              {visibleResultSample?.map((item: OrderLabOutcomeImage, index: number) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="content__item">
                      <div className="content__item__image">
                        <img src={BASE_LINK_IMAGE_V2 + item.data} alt="#" />
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </Dialog>
    </Dialog>
  );
};

export default OrderDetailLab;
