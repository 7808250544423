import { ProductOutputModel } from "@Models";
import { ProductService } from "@Services";
import { useEffect, useState } from "react";

const useProductSelect = () => {
  const [dataProduct, setDataProduct] = useState<ProductOutputModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDataProducts = async () => {
      ProductService.filterProducts({
        page: 1,
        limit: 1000,
        source: "feed",
        // searchValue: inputValue,
      })
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            setDataProduct(res.data.data);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };
    fetchDataProducts();
  }, []);

  return {
    data: dataProduct,
    loading,
  };
};

export default useProductSelect;
