import { REQUEST_URL } from "@Constants";
import { OrderInputModel, OrderSampleInputModel, PaginationQueryModel } from "@Models";
import { RequestUtil } from "@Utils";

export const OrderService = {
  filterOrders: (
    query: PaginationQueryModel & {
      status?: string;
      from?: string;
      to?: string;
      strSearch?: string;
      source?: string;
    } = {
      page: 1,
      limit: 10,
    }
  ) => {
    const url = REQUEST_URL.CUSTOMER_ORDER;

    return RequestUtil.get({ url, query: query });
  },

  createOrder: (data: {
    customerId: number;
    creatorId: number;
    salesOrgId: number;
    distributionChannelId: number;
    address: string;
    currency: string;
    requiredDate: string;
    totalDiscount: number;
    vat: number;
    orderAmount: number;
    source: string;
    note: string;
    orderDetails: {
      itemId: number;
      divisionId: number;
      discount: number;
      quantity: number;
      unitPrice: number;
      saleUnit: string;
      baseUoM: string;
      dimension: string;
      subTotal: number;
    }[];
  }) => {
    const url = REQUEST_URL.ORDER;

    return RequestUtil.post({ url, data });
  },

  createOrderLab: (data: OrderInputModel) => {
    const url = REQUEST_URL.CREATE_ORDER_LAB;

    return RequestUtil.post({ url, data });
  },

  getOrderById: (orderId: string) => {
    const url = `${REQUEST_URL.ORDER}/${orderId}`;

    return RequestUtil.get({ url });
  },

  updateOrderDraft: (data: {
    customerId: number;
    creatorId: number;
    salesOrgId: number;
    distributionChannelId: number;
    address: string;
    currency: string;
    requiredDate: string;
    totalDiscount: number;
    vat: number;
    orderAmount: number;
    source: string;
    note: string;
    isDraft: boolean;
    draftId: number;
    orderDetails: {
      itemId: number;
      divisionId: number;
      discount: number;
      quantity: number;
      unitPrice: number;
      saleUnit: string;
      baseUoM: string;
      dimension: string;
      subTotal: number;
    }[];
  }) => {
    const url = REQUEST_URL.CREATE_ORDER_DRAFT;

    return RequestUtil.post({ url, data });
  },

  deleteOrderDraft: (data: { orderGroupId: number; source: string; customerId: number }) => {
    const url = REQUEST_URL.DELETE_ORDER_DRAFT;

    return RequestUtil.post({ url, data });
  },

  updateOrderFeed: (data: {
    address: string;
    requiredDate: string;
    vat: number;
    orderAmount: number;
    orderGroupId: number;
    note: string;
    orderDetails: {
      itemId: number;
      divisionId: number;
      discount: number;
      quantity: number;
      unitPrice: number;
      saleUnit: string;
      baseUoM: string;
      dimension: string;
      subTotal: number;
    }[];
  }) => {
    const url = REQUEST_URL.UPDATE_ORDER_FEED;

    return RequestUtil.put({ url, data });
  },

  updateOrderVet: (data: {
    address: string;
    requiredDate: string;
    vat: number;
    orderAmount: number;
    orderGroupId: number;
    note: string;
    orderDetails: {
      itemId: number;
      divisionId: number;
      discount: number;
      quantity: number;
      unitPrice: number;
      saleUnit: string;
      baseUoM: string;
      dimension: string;
      subTotal: number;
    }[];
  }) => {
    const url = REQUEST_URL.UPDATE_ORDER_VET;

    return RequestUtil.put({ url, data });
  },

  updateOrderLab: (id: string, data: OrderInputModel) => {
    const url = REQUEST_URL.UPDATE_ORDER_LAB.replace("{id}", id);

    return RequestUtil.put({ url, data });
  },

  updateOrderLabStatus: (id: string, data: { status: string }) => {
    const url = REQUEST_URL.UPDATE_ORDER_LAB_STATUS.replace("{id}", id);

    return RequestUtil.put({ url, data });
  },

  updateOrderLabSample: (data: OrderSampleInputModel) => {
    const url = REQUEST_URL.UPDATE_ORDER_LAB_SAMPLE;

    return RequestUtil.put({ url, data });
  },

  exportOrderLabSample: (id: string, data: any) => {
    const url = REQUEST_URL.EXPORT_ORDER_LAB_SAMPLE.replace("{id}", id);

    return RequestUtil.post({ url, data });
  },

  importOrderLabSample: (id: string, data: any) => {
    const url = REQUEST_URL.IMPORT_ORDER_LAB_SAMPLE.replace("{id}", id);

    return RequestUtil.post({ url, data });
  },

  cancelOrder: (data: {
    reasonStatus: string;
    orderGroupId: number;
    reason: string;
    source: string;
  }) => {
    const url = REQUEST_URL.CANCEL_ORDER_FEED;

    return RequestUtil.put({ url, data });
  },

  cancelOrderLab: (data: {
    reasonStatus: string;
    orderGroupId: number;
    reason: string;
    source: string;
  }) => {
    const url = REQUEST_URL.CANCEL_ORDER_LAB;

    return RequestUtil.put({ url, data });
  },

  getDeliveryOrderById(query: { orderId: any }) {
    const url = REQUEST_URL.DELIVERY_ORDER;
    return RequestUtil.get({ url, query: query });
  },

  cancelOrderFarm: (data: {
    reasonStatus: string;
    orderGroupId: number;
    reason: string;
    source: string;
  }) => {
    const url = REQUEST_URL.CANCEL_ORDER_FARM;

    return RequestUtil.put({ url, data });
  },

  getRequestType: () => {
    const url = REQUEST_URL.REQUEST_TYPE_GET_OR_CREATE;
    return RequestUtil.get({ url });
  },

  getListLabSampleGroupCode() {
    const url = `${REQUEST_URL.GET_LIST_LAB_SAMPLE_GROUP_CODE}`;
    return RequestUtil.get({ url });
  },
};
